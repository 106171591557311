import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AdminLogin } from "src/components/Admin/Login";
import { Settings as ISettings, TrueOrFalse } from "src/models/Settings";

import { ref, set } from "firebase/database";
import { firebaseDB } from "src/utils/firebase";
import { useAppSelector } from "src/hooks/useAppSelector";

export const Settings = () => {
  const navigate = useNavigate();

  const currentSettings = useAppSelector((state) => state.settings.value);

  const [settings, setSettings] = useState<ISettings>(currentSettings);
  const [isLoading, setLoading] = useState(false);

  // Auth
  const isAdmin = useAppSelector((state) => state.users.isAdmin);
  if (!isAdmin) {
    return <AdminLogin />;
  }

  const updateSettings = (partialData: Partial<ISettings>) => {
    setSettings({ ...settings, ...partialData });
  };

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    set(ref(firebaseDB, "settings"), settings)
      .then(() => {
        setLoading(false);
        navigate(-1);
      })
      .catch((error) => {
        setLoading(false);
        alert(JSON.stringify(error));
      });
  };

  const trueOrFalseOptions = (
    <>
      <option value="T">Show</option>
      <option value="F">Hide</option>
    </>
  );

  return (
    <form className="row m-3 g-3" onSubmit={handleSubmit}>
      {/* Settings */}
      <div className="col-12">
        <h3>Settings</h3>
        <hr className="mb-0" />
      </div>

      {/* SECTION 1 - MY PROFILE */}

      <div className="col-12">
        <h5 className="mt-4">My Profile</h5>
        <hr className="mb-0" />
      </div>

      {/* Questions Button */}
      <div className="col-4">
        <label className="form-label">Questions Button</label>
        <select
          className="form-select"
          value={settings.myQuestions}
          onChange={(e) =>
            updateSettings({ myQuestions: e.target.value as TrueOrFalse })
          }
          required
        >
          {trueOrFalseOptions}
        </select>
      </div>

      {/* Gallery Button */}
      <div className="col-4">
        <label className="form-label">Gallery Button</label>
        <select
          className="form-select"
          value={settings.myAlbums}
          onChange={(e) =>
            updateSettings({ myAlbums: e.target.value as TrueOrFalse })
          }
          required
        >
          {trueOrFalseOptions}
        </select>
      </div>

      {/* Connections Button */}
      <div className="col-4">
        <label className="form-label">Connections Button</label>
        <select
          className="form-select"
          value={settings.myConnections}
          onChange={(e) =>
            updateSettings({ myConnections: e.target.value as TrueOrFalse })
          }
          required
        >
          {trueOrFalseOptions}
        </select>
      </div>

      {/* SECTION 2 - CONNECTIONS PAGE */}

      <div className="col-12">
        <h5 className="mt-4">Connections Page</h5>
        <hr className="mb-0" />
      </div>

      {/* Video Call Button */}
      <div className="col-4">
        <label className="form-label">Video Call Button</label>
        <select
          className="form-select"
          value={settings.connectionVideoCalls}
          onChange={(e) =>
            updateSettings({
              connectionVideoCalls: e.target.value as TrueOrFalse,
            })
          }
          required
        >
          {trueOrFalseOptions}
        </select>
      </div>

      {/* Connections Profile Button */}
      <div className="col-4">
        <label className="form-label">View Profile Button</label>
        <select
          className="form-select"
          value={settings.connectionProfile}
          onChange={(e) =>
            updateSettings({ connectionProfile: e.target.value as TrueOrFalse })
          }
          required
        >
          {trueOrFalseOptions}
        </select>
      </div>

      {/* Chat Button */}
      <div className="col-4">
        <label className="form-label">Chat Button</label>
        <select
          className="form-select"
          value={settings.connectionChat}
          onChange={(e) =>
            updateSettings({ connectionChat: e.target.value as TrueOrFalse })
          }
          required
        >
          {trueOrFalseOptions}
        </select>
      </div>

      {/* SECTION 3 - OTHERS PROFILE PAGE */}

      <div className="col-12">
        <h5 className="mt-4">Other&apos;s Profile Page</h5>
        <hr className="mb-0" />
      </div>

      {/* Questions Button */}
      <div className="col-4">
        <label className="form-label">Questions Button</label>
        <select
          className="form-select"
          value={settings.connectionQuestions}
          onChange={(e) =>
            updateSettings({
              connectionQuestions: e.target.value as TrueOrFalse,
            })
          }
          required
        >
          {trueOrFalseOptions}
        </select>
      </div>

      {/* Albums Button */}
      <div className="col-4">
        <label className="form-label">Gallery Button</label>
        <select
          className="form-select"
          value={settings.connectionAlbums}
          onChange={(e) =>
            updateSettings({
              connectionAlbums: e.target.value as TrueOrFalse,
            })
          }
          required
        >
          {trueOrFalseOptions}
        </select>
      </div>

      {/* SECTION 4 - APP SETTINGS */}

      <div className="col-12">
        <h5 className="mt-4">App Settings</h5>
        <hr className="mb-0" />
      </div>

      {/* Chat Request System */}
      <div className="col-6">
        <label className="form-label">Enable Chat Request System</label>
        <select
          className="form-select"
          value={settings.chatRequestSystem}
          onChange={(e) =>
            updateSettings({
              chatRequestSystem: e.target.value as TrueOrFalse,
            })
          }
          required
        >
          <option value="T">
            Yes - People have to send a request before chatting
          </option>
          <option value="F">
            No - People can chat with each other directly without request
          </option>
        </select>
      </div>

      {/* BUTTONS */}

      <div className="col-12">
        <hr className="mt-4 mb-0" />
      </div>

      <div className="col-12">
        <button
          type="submit"
          className="btn btn-success me-2"
          disabled={isLoading}
        >
          {isLoading ? "Please Wait..." : "Save"}
        </button>
        <button
          type="button"
          className="btn btn-secondary me-2"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
