import { Theme } from "./Theme";

export type UserType = "primary" | "secondary";
export type Gender = "M" | "F";

export interface User {
  firstName: string;
  lastName?: string;
  password?: string;
  gender: Gender;
  type: UserType;
  theme: Theme;

  photo?: string;
  photoURL?: string;

  questionsPhoto?: string;
  questionsPhotoURL?: string;

  chatPhoto?: string;
  chatPhotoURL?: string;

  status?: string;
  bio?: string;
  answers?: Record<string, string>;
  connections?: Record<string, boolean>;
}

export const getFullName = (user: User) => {
  return (
    user.firstName.trim() + (user.lastName ? " " + user.lastName.trim() : "")
  );
};

export const getQualifiedName = (userSlug: string, user: User) => {
  if (user.firstName.toLowerCase().trim() === userSlug) {
    return getFullName(user);
  } else {
    return (
      userSlug.charAt(0).toUpperCase() +
      userSlug.slice(1).toLowerCase() +
      " (" +
      getFullName(user) +
      ")"
    );
  }
};

export const getProfilePhotoURL = (user: User) => {
  return user.photoURL;
};

export const getQuestionsPhotoURL = (user: User) => {
  return user.questionsPhotoURL ? user.questionsPhotoURL : user.photoURL;
};

export const getChatPhotoURL = (user: User) => {
  return user.chatPhotoURL ? user.chatPhotoURL : user.photoURL;
};
