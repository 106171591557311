import { useEffect, useMemo, useRef } from "react";

import { Message } from "../Message";

import { Theme } from "src/models/Theme";
import { Message as IMessage } from "src/models/Message";

import { setUserToShow } from "src/store/users";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { addMessage, clearMessages, setMessages } from "src/store/messages";

import {
  get,
  off,
  ref,
  query,
  limitToLast,
  onChildAdded,
} from "firebase/database";
import { firebaseDB } from "src/utils/firebase";

import "./MessageList.scoped.scss";

interface PropTypes {
  className?: string;
  theme: Theme;
  senderSlug: string;
  receiverSlug: string;
}

export const MessageList = (props: PropTypes) => {
  // Props
  const { className, theme, senderSlug, receiverSlug } = props;

  // Redux
  const dispatch = useAppDispatch();
  const messages = useAppSelector((state) => state.messages.list);
  const authUserSlug = useAppSelector(
    (state) => state.users.authUserSlug as string
  );

  // Computed
  const chatSlug = useMemo(() => {
    return [senderSlug, receiverSlug].sort().join("-");
  }, [senderSlug, receiverSlug]);

  // Scroll to Bottom
  const messageAnchorRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    messageAnchorRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Load Messages
  useEffect(() => {
    const dataPath = `messages/${chatSlug}`;
    const last50MessagesRef = query(ref(firebaseDB, dataPath), limitToLast(50));
    const last4MessagesRef = query(ref(firebaseDB, dataPath), limitToLast(4));

    // Get last 50 messages
    get(last50MessagesRef).then((snapshot) => {
      const allMessages = snapshot.val() as Record<string, IMessage> | null;
      if (allMessages) {
        dispatch(setMessages(allMessages));
      }

      // Check every new message that comes in
      onChildAdded(last4MessagesRef, (data) => {
        const message = data.val() as IMessage | null;
        if (message) {
          dispatch(addMessage(message));
          setTimeout(() => {
            scrollToBottom();
          }, 100);
        }
      });
    });

    // Detach listners
    return () => {
      // Remove firebase listener
      off(last4MessagesRef, "child_added");
      // Reset redux
      dispatch(clearMessages());
      dispatch(setUserToShow(authUserSlug));
    };
  }, [chatSlug, authUserSlug, dispatch]);

  // JSX
  return (
    <div className={`mc-message-list ${className ? className : ""}`}>
      {messages &&
        Object.keys(messages).map((messageTimestamp) => {
          const message = messages[messageTimestamp];
          // Mera message
          if (message.sender === senderSlug) {
            return (
              <Message key={messageTimestamp} theme={theme} className="mb-4">
                {message.body}
              </Message>
            );
          }
          // Samne wale ka message
          else {
            return (
              <Message key={messageTimestamp} className="mb-4 self-flex-end">
                {message.body}
              </Message>
            );
          }
        })}
      {/* Used for scrolling to bottom */}
      <div ref={messageAnchorRef} className="mc-message-list__anchor"></div>
    </div>
  );
};
