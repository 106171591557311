import isEmpty from "lodash.isempty";
import { useNavigate } from "react-router-dom";

import { Page } from "src/models/Page";
import { getHexCode } from "src/models/Theme";
import { getQualifiedName } from "src/models/User";

import { AdminLogin } from "src/components/Admin/Login";
import { Loading } from "src/components/Layout/Loading";

import { setCurrentPage } from "src/store/users";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";

export const Home = () => {
  // Redux & Router
  const userList = useAppSelector((state) => state.users.list);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // Auth
  const isAdmin = useAppSelector((state) => state.users.isAdmin);
  if (!isAdmin) {
    return <AdminLogin />;
  }

  // Loading
  if (isEmpty(userList)) {
    return <Loading />;
  }

  // Click events

  const handleAdminClick = () => {
    navigate("/admin");
  };

  const handleUserClick = (slug: string) => {
    dispatch(setCurrentPage(Page.Profile));
    navigate(`/user/${slug}`);
  };

  // Output
  return (
    <div className="m-3">
      <div className="container-fluid">
        <div className="row">
          {/* Title */}
          <div className="col-12">
            <h3 className="mb-3">Select Account</h3>
          </div>

          {/* Admin Button */}
          <div className="col-12">
            <button
              className="btn btn-success me-2 mb-2 w-100"
              onClick={handleAdminClick}
            >
              <strong>Admin Panel</strong>
            </button>
          </div>

          {/* User Buttons */}
          {Object.keys(userList).map((userSlug) => {
            const user = userList[userSlug];
            return (
              <div className="col-3" key={userSlug}>
                <button
                  className="btn btn-primary me-2 mb-2 w-100"
                  style={{
                    backgroundColor: getHexCode(user.theme),
                    borderColor: getHexCode(user.theme),
                  }}
                  onClick={() => handleUserClick(userSlug)}
                >
                  {getQualifiedName(userSlug, user)}
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
