import { useMemo } from "react";

import { Frame } from "../Common/Frame";
import { HiddenNavigation } from "../Common/HiddenNavigation";

import { getProfilePhotoURL, User } from "src/models/User";
import {
  getDoodleImage,
  getGradientImage,
  getProfileConnectionsButtonImage,
  getProfileGalleryButtonImage,
  getProfileQuestionsButtonImage,
} from "src/models/Theme";

import {
  goToAlbumsPage,
  goToConnectionsPage,
  goToQuestionsPage,
  setUserToShow,
} from "src/store/users";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";

import "./Profile.scoped.scss";
import "./Profile.scss";

export const Profile = () => {
  const dispatch = useAppDispatch();
  const settings = useAppSelector((state) => state.settings.value);
  const userToShow = useAppSelector((state) => state.users.userToShow as User);
  const authUserSlug = useAppSelector(
    (state) => state.users.authUserSlug as string
  );
  const userToShowSlug = useAppSelector(
    (state) => state.users.userToShowSlug as string
  );
  /* Added 'as' here since these can't be undefined.
    This component doesn't render if they are undefined */

  // Memo
  const isMyProfile = useMemo(() => {
    return authUserSlug === userToShowSlug;
  }, [authUserSlug, userToShowSlug]);

  // Button clicks
  const handleBackNavigation = () => {
    if (!isMyProfile) {
      dispatch(setUserToShow(authUserSlug));
      dispatch(goToConnectionsPage());
    }
  };

  const handleQuestionsClick = () => {
    dispatch(goToQuestionsPage());
  };

  const handleGalleryClick = () => {
    dispatch(goToAlbumsPage());
  };

  const handleConnectionsClick = () => {
    dispatch(goToConnectionsPage());
  };

  // JSX
  return (
    <div
      className="mc-profile"
      style={{
        background: `url("${getGradientImage(
          userToShow.theme
        )}") no-repeat center center`,
      }}
    >
      {/* Left Half */}
      <div className="mc-profile__left">
        <div
          className="mc-profile__doodles"
          style={{
            background: `url("${getDoodleImage(
              userToShow.theme
            )}") no-repeat center right`,
          }}
        />
        <Frame
          className="mc-profile__photo"
          src={getProfilePhotoURL(userToShow)}
        />
      </div>

      {/* Right Half */}
      <div className="mc-profile__right">
        <div className="mc-profile__main">
          {/* Logo */}
          <div className="mc-profile__main-top">
            <img src="/images/brand/logo_v2.png" />
          </div>

          {/* Profile */}
          <div className="mc-profile__main-bottom">
            {/* Name */}
            <div className="mc-profile__name">
              {userToShow.firstName}
              {userToShow.lastName && userToShow.lastName !== "" && (
                <>
                  <br />
                  {userToShow.lastName}
                </>
              )}
            </div>

            {/* Bio */}
            {userToShow.bio && (
              <div className="mc-profile__bio">
                {userToShow.bio.split("\n").map((bioLine, index) => (
                  <div key={index}>
                    {bioLine}
                    <br />
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="mc-profile__buttons">
        {/* Questions Button */}
        {((isMyProfile && settings.myQuestions === "T") ||
          (!isMyProfile && settings.connectionQuestions === "T")) && (
          <img
            src={getProfileQuestionsButtonImage(userToShow.theme)}
            onClick={handleQuestionsClick}
          />
        )}

        {/* Gallery Button */}
        {((isMyProfile && settings.myAlbums === "T") ||
          (!isMyProfile && settings.connectionAlbums === "T")) && (
          <img
            src={getProfileGalleryButtonImage(userToShow.theme)}
            onClick={handleGalleryClick}
          />
        )}

        {/* Connections Button */}
        {isMyProfile && settings.myConnections === "T" && (
          <img
            src={getProfileConnectionsButtonImage(userToShow.theme)}
            onClick={handleConnectionsClick}
          />
        )}
      </div>

      {/* Invisible Back Button */}
      <HiddenNavigation onLeftClick={handleBackNavigation} />
    </div>
  );
};
