import isEmpty from "lodash.isempty";

import { AdminLogin } from "src/components/Admin/Login";
import { Loading } from "src/components/Layout/Loading";

import {
  getChatRequestStatusColor,
  getChatRequestStatusName,
} from "src/models/ChatRequest";
import { getQualifiedName } from "src/models/User";
import { useAppSelector } from "src/hooks/useAppSelector";

export const Chat = () => {
  // Get users list
  const userList = useAppSelector((state) => state.users.list);

  // Chat Requests
  const allChatRequests = useAppSelector((state) => state.chatRequests.list);

  // Middleware
  const isAdmin = useAppSelector((state) => state.users.isAdmin);
  if (!isAdmin) {
    return <AdminLogin />;
  }
  if (isEmpty(userList)) {
    return <Loading />;
  }

  // Return chats table
  return (
    <div className="m-3">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">From</th>
            <th scope="col">To</th>
            <th scope="col">Date/Time</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(allChatRequests).map((requestID) => {
            const request = allChatRequests[requestID];
            return (
              <tr key={request.timestamp}>
                <td>
                  {getQualifiedName(request.from, userList[request.from])}
                </td>
                <td>{getQualifiedName(request.to, userList[request.to])}</td>
                <td>{new Date(request.timestamp).toTimeString()}</td>
                <td
                  className={`text-${getChatRequestStatusColor(
                    request.status
                  )}`}
                  style={{ fontWeight: 600 }}
                >
                  {getChatRequestStatusName(request.status)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
