import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Settings } from "src/models/Settings";

export interface SettingsState {
  value: Settings;
}

const initialState: SettingsState = {
  value: {
    myQuestions: "F",
    myAlbums: "F",
    myConnections: "F",
    connectionProfile: "F",
    connectionQuestions: "F",
    connectionAlbums: "F",
    connectionChat: "F",
    connectionVideoCalls: "F",
    chatRequestSystem: "F",
  },
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    updateSettings: (state, action: PayloadAction<Partial<Settings>>) => {
      state.value = { ...state.value, ...action.payload };
    },
  },
});

export const { updateSettings } = settingsSlice.actions;

export const settingsReducer = settingsSlice.reducer;
