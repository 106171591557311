import { useNavigate } from "react-router-dom";

import { AdminLogin } from "src/components/Admin/Login";
import { useAppSelector } from "src/hooks/useAppSelector";

export const AdminHome = () => {
  const navigate = useNavigate();

  // Auth
  const isAdmin = useAppSelector((state) => state.users.isAdmin);
  if (!isAdmin) {
    return <AdminLogin />;
  }

  const handleUserManagementClick = () => {
    navigate("/admin/users");
  };

  const handleChatManagementClick = () => {
    navigate("/admin/chats");
  };

  const handleSendNotification = () => {
    navigate("/admin/send-notification");
  };

  const handleSettingsClick = () => {
    navigate("/admin/settings");
  };

  return (
    <div className="m-3">
      {/* Title */}
      <h3 className="mb-3">Admin Panel</h3>

      {/* Users Button */}
      <button
        className="btn btn-success me-2 mb-2"
        onClick={handleUserManagementClick}
      >
        User Management
      </button>

      {/* Chat Button */}
      <button
        className="btn btn-success me-2 mb-2"
        onClick={handleChatManagementClick}
      >
        Chat Request Tracker
      </button>

      <br />

      {/* Send Notification */}
      <button
        className="btn btn-success me-2 mb-2"
        onClick={handleSendNotification}
      >
        Send Notification
      </button>

      {/* Settings Button */}
      <button
        className="btn btn-success me-2 mb-2"
        onClick={handleSettingsClick}
      >
        Settings
      </button>
    </div>
  );
};
