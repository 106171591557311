import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Message } from "src/models/Message";

export interface MessageState {
  list: Record<string, Message>;
}

const initialState: MessageState = {
  list: {},
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<Record<string, Message>>) => {
      state.list = action.payload;
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      const newMessage = action.payload;
      if (state.list) {
        state.list[newMessage.timestamp] = newMessage;
      }
    },
    clearMessages: (state) => {
      state.list = {};
    },
  },
});

export const { setMessages, addMessage, clearMessages } = messagesSlice.actions;

export const messagesReducer = messagesSlice.reducer;
