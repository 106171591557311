import React, {
  KeyboardEvent,
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { useIsTyping } from "use-is-typing";
import EmojiPicker, { IEmojiData } from "emoji-picker-react";

import { off, onValue, ref, set } from "firebase/database";
import { firebaseDB } from "src/utils/firebase";

import "./MessageBox.scoped.scss";

interface PropTypes {
  className?: string;
  senderSlug: string;
  receiverSlug: string;
}

interface TypingIndicator {
  status: boolean;
  updatedAt: number;
}

export const MessageBox = (props: PropTypes) => {
  // Props
  const { senderSlug, receiverSlug, className } = props;

  // Common State
  const [message, setMessage] = useState("");

  // My Typing Indicator Timestamp

  const [currentTimestamp, setCurrentTimestamp] = useState(
    new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTimestamp(new Date().getTime());
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // My Typing Indicator

  const [isTyping, inputRegister] = useIsTyping({ timeout: 2000 });

  const myTypingSlug = useMemo(() => {
    return `${senderSlug}-${receiverSlug}`;
  }, [senderSlug, receiverSlug]);

  useEffect(() => {
    // Typing on
    if (isTyping) {
      const data: TypingIndicator = {
        status: true,
        updatedAt: currentTimestamp,
      };
      set(ref(firebaseDB, `typing/${myTypingSlug}`), data);
    }
  }, [isTyping, myTypingSlug, currentTimestamp]);

  useEffect(() => {
    // Typing off
    if (!isTyping) {
      const data: TypingIndicator = {
        status: false,
        updatedAt: new Date().getTime(),
      };
      set(ref(firebaseDB, `typing/${myTypingSlug}`), data);
    }
  }, [isTyping, myTypingSlug]);

  // Saamne Wale Ka Typing Indicator

  const othersTypingSlug = useMemo(() => {
    return `${receiverSlug}-${senderSlug}`;
  }, [receiverSlug, senderSlug]);

  const [othersTypingIndicator, setOthersTypingIndicator] =
    useState<TypingIndicator | null>(null);

  const othersTypingFirebaseRef = useMemo(() => {
    return ref(firebaseDB, `typing/${othersTypingSlug}`);
  }, [othersTypingSlug]);

  useEffect(() => {
    if (othersTypingFirebaseRef) {
      onValue(othersTypingFirebaseRef, (snapshot) => {
        const data = snapshot.val() as TypingIndicator | null;
        setOthersTypingIndicator(data);
      });
      return () => {
        off(othersTypingFirebaseRef, "value");
      };
    }
  }, [othersTypingSlug, othersTypingFirebaseRef]);

  const isOtherTyping = useMemo(() => {
    if (!othersTypingIndicator) {
      return false;
    }
    if (othersTypingIndicator.status === false) {
      return false;
    }
    const currentTimestamp = new Date().getTime();
    if (currentTimestamp - othersTypingIndicator.updatedAt > 5000) {
      return false;
    }
    return true;
  }, [othersTypingIndicator]);

  // Emoji Picker

  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);

  const emojiPickerRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target as Node)
    ) {
      setEmojiPickerVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const handleEmojiClick = () => {
    setEmojiPickerVisible(true);
  };

  const handleEmojiSelect = (
    event: React.MouseEvent,
    emojiObject: IEmojiData
  ) => {
    setMessage(message + emojiObject.emoji);
  };

  // Sending Message

  const [isSending, setIsSending] = useState(false);

  const chatSlug = useMemo(() => {
    return [senderSlug, receiverSlug].sort().join("-");
  }, [senderSlug, receiverSlug]);

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      // Only send non-empty messages
      if (message.trim() !== "") {
        setIsSending(true);
        const timestamp = new Date().getTime();
        set(ref(firebaseDB, `messages/${chatSlug}/${timestamp}`), {
          sender: senderSlug,
          body: message,
          timestamp: timestamp,
        })
          .then(() => {
            setMessage("");
          })
          .catch((error) => {
            alert(JSON.stringify(error));
          })
          .then(() => {
            setIsSending(false);
          });
      }
    }
  };

  // JSX
  return (
    <div className={`mc-messagebox ${className ? className : ""} shadow-lg`}>
      {/* Emoji Button */}
      <div className="mc-messagebox__emoji" onClick={handleEmojiClick}>
        <img src="/images/buttons/emoji.png" />
      </div>
      {isEmojiPickerVisible && (
        <div ref={emojiPickerRef} className="mc-messagebox__emoji-picker">
          <EmojiPicker onEmojiClick={handleEmojiSelect} native={true} />
        </div>
      )}

      {/* Input */}
      <div className="mc-messagebox__input">
        <input
          ref={inputRegister}
          type="text"
          placeholder="Your Message..."
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleKeyDown}
          disabled={isSending}
        />
      </div>

      {/* Typing Indicator */}
      {isOtherTyping && (
        <div className="mc-messagebox__typing">
          <img src="/images/icons/typing.png" />
        </div>
      )}
    </div>
  );
};
