import { useMemo, useState } from "react";

import { User } from "src/models/User";
import { Page } from "src/models/Page";
import { Album, albumImages, alumbs as albumData } from "src/data/albums";
import { getConnectionsDoodleImage, getGradientImage } from "src/models/Theme";

import { HiddenNavigation } from "../Common/HiddenNavigation";

import { setCurrentPage } from "src/store/users";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";

import "./Albums.scoped.scss";

export const Albums = () => {
  const dispatch = useAppDispatch();

  // User data
  const userToShow = useAppSelector((state) => state.users.userToShow as User);
  const userToShowSlug = useAppSelector(
    (state) => state.users.userToShowSlug as string
  );

  // Albums

  const myAlbums = useMemo(
    () => (albumData[userToShowSlug] ? albumData[userToShowSlug] : []),
    [userToShowSlug]
  );
  const leftAlbums = useMemo(
    () => myAlbums.slice(0, myAlbums.length / 2),
    [myAlbums]
  );
  const rightAlbums = useMemo(
    () => myAlbums.slice(myAlbums.length / 2),
    [myAlbums]
  );

  const [currentAlbum, setCurrentAlbum] = useState<Album>();

  // Single Image

  const currentUserAlbumImages = useMemo(() => {
    return albumImages[userToShowSlug] ? albumImages[userToShowSlug] : {};
  }, [userToShowSlug]);

  const [currentImage, setCurrentImage] = useState<number>();

  const isFirstImage = useMemo(() => {
    return currentImage === 0;
  }, [currentImage]);

  const isLastImage = useMemo(() => {
    if (currentAlbum && currentImage !== undefined) {
      return (
        currentImage === currentUserAlbumImages[currentAlbum.slug].length - 1
      );
    }
  }, [currentAlbum, currentImage, currentUserAlbumImages]);

  const onSingleAlbumClick = () => {
    setCurrentImage(0);
  };

  const handleNextClick = () => {
    if (currentAlbum && currentImage !== undefined) {
      if (!isLastImage) {
        setCurrentImage(currentImage + 1);
      }
    }
  };

  const handlePrevClick = () => {
    if (!isFirstImage && currentImage !== undefined) {
      setCurrentImage(currentImage - 1);
    }
  };

  // Navigation

  const handleBackNavigation = () => {
    if (currentImage !== undefined) {
      setCurrentImage(undefined);
    } else if (currentAlbum) {
      setCurrentAlbum(undefined);
    } else {
      dispatch(setCurrentPage(Page.Profile));
    }
  };

  // JSX
  return (
    <div
      className="mc-albums"
      style={{
        background: `url("${getGradientImage(
          userToShow.theme
        )}") no-repeat center center`,
      }}
    >
      <div
        className="mc-albums__doodles"
        style={{
          background: `url("${getConnectionsDoodleImage(
            userToShow.theme
          )}") no-repeat center center`,
          opacity: userToShow.theme === "P" ? "0.5" : "1",
        }}
      />

      {/* Show Album List */}
      {!currentAlbum && (
        <>
          {/* Cover Image */}
          <img
            className="mc-albums__cover"
            src={`/images/albums/${userToShowSlug}/cover.png`}
          />

          {/* Album Names */}
          <div className="mc-albums__contents row">
            {/* Left Albums */}
            <div className="mc-albums__names col">
              {leftAlbums.map((album) => (
                <div
                  key={`${userToShowSlug}-${album.slug}`}
                  className="mc-albums__name mc-albums__name--right"
                  onClick={() => setCurrentAlbum(album)}
                >
                  {album.name}
                </div>
              ))}
            </div>

            {/* Empty Space */}
            <div className="col"></div>
            <div className="col"></div>
            <div className="col"></div>

            {/* Right Albums */}
            <div className="mc-albums__names col">
              {rightAlbums.map((album) => (
                <div
                  key={`${userToShowSlug}-${album.slug}`}
                  className="mc-albums__name mc-albums__name--left"
                  onClick={() => setCurrentAlbum(album)}
                >
                  {album.name}
                </div>
              ))}
            </div>
          </div>
        </>
      )}

      {/* Show Single Album Images */}
      {currentAlbum && (
        <>
          <div className="mc-albums__single-album" onClick={onSingleAlbumClick}>
            <img
              src={`/images/albums/${userToShowSlug}/${currentAlbum.slug}.png`}
            />
          </div>

          {/* Show Single Image */}
          {currentImage !== undefined && (
            <div className="mc-albums__single-image-container">
              {/* Background Gradient */}
              <div
                className="mc-albums__single-image-overlay"
                style={{
                  background: `url("${getGradientImage(
                    userToShow.theme
                  )}") no-repeat center center`,
                }}
              ></div>

              {/* Prev Button */}
              <img
                className={`mc-albums__single-btn ${
                  isFirstImage ? "hidden" : ""
                }`}
                src="/images/buttons/arrow_left.png"
                onClick={handlePrevClick}
              />
              {/* Current Image */}
              <div className={`mc-albums__single-image`}>
                <img
                  src={`/images/albums/${userToShowSlug}/${currentAlbum.slug}/${
                    currentUserAlbumImages[currentAlbum.slug][currentImage]
                  }`}
                />
              </div>
              {/* Next Button */}
              <img
                className={`mc-albums__single-btn ${
                  isLastImage ? "hidden" : ""
                }`}
                src="/images/buttons/arrow_right.png"
                onClick={handleNextClick}
              />
            </div>
          )}
        </>
      )}

      {/* Back Button */}
      <HiddenNavigation onLeftClick={handleBackNavigation} />
    </div>
  );
};
