export enum Theme {
  Blue = "B",
  Orange = "O",
  Yellow = "Y",
  Pink = "P",
  Lavender = "L",
}

// Color & Gradients

export const getHexCode = (theme: Theme) => {
  switch (theme) {
    case Theme.Blue:
      return "#07BDDB";
    case Theme.Orange:
      return "#F29B00";
    case Theme.Yellow:
      return "#DDB300";
    case Theme.Pink:
      return "#FF7D99";
    case Theme.Lavender:
      return "#9173EF";
    default:
      return "#07BDDB";
  }
};

export const getLightHexCode = (theme: Theme) => {
  switch (theme) {
    case Theme.Blue:
      return "#07BDDB";
    case Theme.Orange:
      return "#F29B00";
    case Theme.Yellow:
      return "#f7d80b";
    case Theme.Pink:
      return "#FF7D99";
    case Theme.Lavender:
      return "#9173EF";
    default:
      return "#07BDDB";
  }
};

export const getGradientImage = (theme: Theme) => {
  return `/images/theme/bg_${theme.toString()}.jpg`;
};

export const getMirrorGradientImage = (theme: Theme) => {
  return `/images/theme/revbg_${theme.toString()}.jpg`;
};

export const getMiniGradientImage = (theme: Theme) => {
  return `/images/theme/mini_bg_${theme.toString()}.jpg`;
};

// Doodle Images

export const getDoodleImage = (theme: Theme) => {
  return `/images/theme/common_doodles_${theme.toString()}.png`;
};

export const getConnectionsDoodleImage = (theme: Theme) => {
  return `/images/theme/connections_doodles_${theme.toString()}.png`;
};

export const getChatDoodleImage = (theme: Theme | "W") => {
  return `/images/theme/chat_doodles_${theme.toString()}.png`;
};

// Button Images

export const getProfileQuestionsButtonImage = (theme: Theme) => {
  return `/images/theme/btn_ques_${theme.toString()}.png`;
};

export const getProfileGalleryButtonImage = (theme: Theme) => {
  return `/images/theme/btn_gallery_${theme.toString()}.png`;
};

export const getProfileConnectionsButtonImage = (theme: Theme) => {
  return `/images/theme/btn_conn_${theme.toString()}.png`;
};
