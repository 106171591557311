import { onValue, ref } from "firebase/database";
import { firebaseDB } from "src/utils/firebase";

import { User } from "src/models/User";
import { Alert } from "src/models/Alert";
import { Settings } from "src/models/Settings";
import { ChatRequest } from "src/models/ChatRequest";
import { VideoRequest } from "src/models/VideoRequest";

import { store } from "src/store";
import { setAlerts } from "src/store/alerts";
import { setUsersList } from "src/store/users";
import { updateSettings } from "src/store/settings";
import { setChatRequests } from "src/store/chat-requests";
import { setVideoRequests } from "src/store/video-requests";

// Load users from firebase
onValue(ref(firebaseDB, "users"), (snapshot) => {
  const data = snapshot.val() as Record<string, User>;
  store.dispatch(setUsersList(data));
});

// Load settings from firebase
onValue(ref(firebaseDB, "settings"), (snapshot) => {
  const data = snapshot.val() as Settings | null;
  if (data) {
    store.dispatch(updateSettings(data));
  }
});

// Load chat requests from firebase
onValue(ref(firebaseDB, "chatRequests"), (snapshot) => {
  const data = snapshot.val() as Record<string, ChatRequest> | null;
  if (data) {
    store.dispatch(setChatRequests(data));
  }
});

// Load alerts from firebase
onValue(ref(firebaseDB, "alerts"), (snapshot) => {
  const data = snapshot.val() as Record<string, Alert> | null;
  if (data) {
    store.dispatch(setAlerts(data));
  }
});

// Load video requests from firebase
onValue(ref(firebaseDB, "videoRequests"), (snapshot) => {
  const data = snapshot.val() as Record<string, VideoRequest> | null;
  if (data) {
    store.dispatch(setVideoRequests(data));
  }
});
