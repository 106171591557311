import "./HiddenNavigation.scoped.scss";

interface PropTypes {
  onLeftClick?: () => void;
  onCenterClick?: () => void;
  onRightClick?: () => void;
}

export const HiddenNavigation = ({
  onLeftClick,
  onCenterClick,
  onRightClick,
}: PropTypes) => {
  return (
    <div className="mc-nav row">
      {/* Left Button */}
      <div
        className={`col ${onLeftClick ? "cursor-pointer" : ""}`}
        onClick={onLeftClick}
      ></div>

      {/* Center Button */}
      <div
        className={`col ${onCenterClick ? "cursor-pointer" : ""}`}
        onClick={onCenterClick}
      ></div>

      {/* Right Button */}
      <div
        className={`col ${onRightClick ? "cursor-pointer" : ""}`}
        onClick={onRightClick}
      ></div>
    </div>
  );
};
