import isEmpty from "lodash.isempty";
import { useRoutes } from "react-router-dom";

import { routes } from "src/routes";
import { Loading } from "./Layout/Loading";
import { useAppSelector } from "src/hooks/useAppSelector";

export const App = () => {
  // Current router page
  const currentPage = useRoutes(routes);

  // Redux data
  const userList = useAppSelector((state) => state.users.list);

  // Show loading screen if data pending
  if (isEmpty(userList)) {
    return <Loading />;
  }
  // Else, show current page
  return currentPage;
};
