import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VideoRequest } from "src/models/VideoRequest";
import { RootState } from ".";

export interface VideoRequestsState {
  list: Record<string, VideoRequest>;
}

const initialState: VideoRequestsState = {
  list: {},
};

export const videoRequestSlice = createSlice({
  name: "videoRequests",
  initialState,
  reducers: {
    setVideoRequests: (
      state,
      action: PayloadAction<Record<string, VideoRequest>>
    ) => {
      state.list = action.payload;
    },
    clearVideoRequests: (state) => {
      state.list = {};
    },
  },
});

const selectAuthUserSlug = (state: RootState) => state.users.authUserSlug;
const selectAllVideoRequests = (state: RootState) => state.videoRequests.list;

export const selectMyOpenVideoRequests = createSelector(
  selectAuthUserSlug,
  selectAllVideoRequests,
  (authUserSlug, allVideoRequests) => {
    const myVidReqs: VideoRequest[] = [];
    Object.keys(allVideoRequests).forEach((reqId) => {
      const vidReq = allVideoRequests[reqId];

      const isLoggedIn = !!authUserSlug;
      const isRelatedToMe =
        vidReq.from === authUserSlug || vidReq.to === authUserSlug;
      const isOpen =
        vidReq.status === "P" ||
        vidReq.status === "A" ||
        (vidReq.status === "R" && vidReq.from === authUserSlug);

      if (isLoggedIn && isRelatedToMe && isOpen) {
        myVidReqs.push(vidReq);
      }
    });
    return myVidReqs;
  }
);

export const { setVideoRequests, clearVideoRequests } =
  videoRequestSlice.actions;

export const videoRequestReducer = videoRequestSlice.reducer;
