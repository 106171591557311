import isEmpty from "lodash.isempty";
import { SyntheticEvent, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { questions } from "src/data/questions";
import { getHexCode, Theme } from "src/models/Theme";
import { User, UserType, Gender, getQualifiedName } from "src/models/User";

import { useAppSelector } from "src/hooks/useAppSelector";

import { AdminLogin } from "src/components/Admin/Login";
import { Loading } from "src/components/Layout/Loading";
import { NotFound } from "src/components/Layout/NotFound";

import {
  ref as storageRef,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { ref as dbRef, set } from "firebase/database";
import { firebaseDB, firebaseStorage } from "src/utils/firebase";

export const UserEdit = () => {
  const navigate = useNavigate();
  const { slug } = useParams();

  // Redux state
  const userList = useAppSelector((state) => state.users.list); // Get all users

  // Component state
  const [currentUser, setCurrentUser] = useState<User | undefined>(
    userList && slug ? userList[slug] : undefined
  );
  const [currentUserImage, setCurrentUserImage] = useState<File | undefined>();
  const [currentUserQuestionsImage, setCurrentUserQuestionsImage] = useState<
    File | undefined
  >();
  const [currentUserChatImage, setCurrentUserChatImage] = useState<
    File | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  // Auth
  const isAdmin = useAppSelector((state) => state.users.isAdmin);
  if (!isAdmin) {
    return <AdminLogin />;
  }

  // Data validations
  if (isEmpty(userList)) {
    return <Loading />;
  }
  if (typeof currentUser === "undefined") {
    return <NotFound />;
  }

  // Helper functions
  const updateCurrentUser = (partialData: Partial<User>) => {
    setCurrentUser({ ...currentUser, ...partialData });
  };

  const updateUsersAnswer = (questionSlug: string, value: string) => {
    updateCurrentUser({
      answers: { ...currentUser.answers, [questionSlug]: value },
    });
  };

  const addUsersConnection = (connectionSlug: string) => {
    updateCurrentUser({
      connections: {
        ...currentUser.connections,
        [connectionSlug]: true,
      },
    });
  };

  const removeUsersConnection = (connectionSlug: string) => {
    updateCurrentUser({
      connections: {
        ...currentUser.connections,
        [connectionSlug]: false,
      },
    });
  };

  const handlePhotoSelect = (type: "P" | "Q" | "C", files: FileList | null) => {
    if (files && files.length > 0) {
      if (type === "P") {
        setCurrentUserImage(files[0]);
      } else if (type === "Q") {
        setCurrentUserQuestionsImage(files[0]);
      } else if (type === "C") {
        setCurrentUserChatImage(files[0]);
      }
    }
  };

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault(); // Stop the page refresh nonsense
    setIsLoading(true);
    const userToUpload = { ...currentUser };
    try {
      // Upload profile photo if present
      if (currentUserImage) {
        const imagePath =
          "/users/" + slug + "." + currentUserImage.name.split(".").pop();
        await uploadBytes(
          storageRef(firebaseStorage, imagePath),
          currentUserImage
        );
        const url = await getDownloadURL(
          storageRef(firebaseStorage, imagePath)
        );
        userToUpload.photo = imagePath;
        userToUpload.photoURL = url;
      }
      // Upload questions photo if present
      if (currentUserQuestionsImage) {
        const imagePath =
          "/users/" +
          slug +
          "_questions." +
          currentUserQuestionsImage.name.split(".").pop();
        await uploadBytes(
          storageRef(firebaseStorage, imagePath),
          currentUserQuestionsImage
        );
        const url = await getDownloadURL(
          storageRef(firebaseStorage, imagePath)
        );
        userToUpload.questionsPhoto = imagePath;
        userToUpload.questionsPhotoURL = url;
      }
      // Upload questions photo if present
      if (currentUserChatImage) {
        const imagePath =
          "/users/" +
          slug +
          "_chat." +
          currentUserChatImage.name.split(".").pop();
        await uploadBytes(
          storageRef(firebaseStorage, imagePath),
          currentUserChatImage
        );
        const url = await getDownloadURL(
          storageRef(firebaseStorage, imagePath)
        );
        userToUpload.chatPhoto = imagePath;
        userToUpload.chatPhotoURL = url;
      }
    } catch (err) {
      setIsLoading(false);
      alert(JSON.stringify(err));
    }
    // Update DB
    updateUserOnFirebaseDB(userToUpload);
  };

  const updateUserOnFirebaseDB = (user: User) => {
    set(dbRef(firebaseDB, "users/" + slug), user)
      .then(() => {
        setIsLoading(false);
        navigate(-1);
      })
      .catch((error) => {
        setIsLoading(false);
        alert(JSON.stringify(error));
      });
  };

  // JSX
  return (
    <form className="row m-3 g-3" onSubmit={handleSubmit}>
      {/* Title */}
      <div className="col-12">
        <h3>Edit User</h3>
      </div>

      {/* First Name */}
      <div className="col-6">
        <label className="form-label">First Name*</label>
        <input
          type="text"
          className="form-control"
          value={currentUser.firstName}
          onChange={(e) => updateCurrentUser({ firstName: e.target.value })}
          required
        />
      </div>

      {/* Last Name */}
      <div className="col-6">
        <label className="form-label">Last Name</label>
        <input
          type="text"
          className="form-control"
          value={currentUser.lastName}
          onChange={(e) => updateCurrentUser({ lastName: e.target.value })}
        />
      </div>

      {/* Gender */}
      <div className="col-6">
        <label className="form-label">Gender*</label>
        <select
          className="form-select"
          value={currentUser.gender}
          onChange={(e) =>
            updateCurrentUser({ gender: e.target.value as Gender })
          }
          required
        >
          <option value="M">Male</option>
          <option value="F">Female</option>
        </select>
      </div>

      {/* Type */}
      <div className="col-6">
        <label className="form-label">Type*</label>
        <select
          className="form-select"
          value={currentUser.type}
          onChange={(e) =>
            updateCurrentUser({ type: e.target.value as UserType })
          }
          required
        >
          <option value="primary">Single</option>
          <option value="secondary">Connection</option>
        </select>
      </div>

      {/* Theme */}
      <div className="col-6">
        <label className="form-label">Theme*</label>
        <select
          className="form-select"
          value={currentUser.theme}
          onChange={(e) =>
            updateCurrentUser({ theme: e.target.value as Theme })
          }
          required
        >
          <option value={Theme.Blue}>Blue</option>
          <option value={Theme.Orange}>Orange</option>
          <option value={Theme.Yellow}>Yellow</option>
          <option value={Theme.Pink}>Pink</option>
          <option value={Theme.Lavender}>Lavender</option>
        </select>
      </div>

      {/* Password */}
      <div className="col-6">
        <label className="form-label">Password</label>
        <input
          type="text"
          className="form-control"
          value={currentUser.password}
          onChange={(e) => updateCurrentUser({ password: e.target.value })}
        />
      </div>

      {/* Photo 1 */}
      <div className="col-4">
        <label className="form-label">Profile Photo</label>
        <input
          className="form-control"
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => handlePhotoSelect("P", e.target.files)}
        />
        <div className="form-text">
          To preserve the old photo, don&apos;t select anything here...
        </div>
      </div>

      {/* Photo 2 */}
      <div className="col-4">
        <label className="form-label">Questions Page Photo</label>
        <input
          className="form-control"
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => handlePhotoSelect("Q", e.target.files)}
        />
        <div className="form-text">
          To preserve the old photo, don&apos;t select anything here...
        </div>
      </div>

      {/* Photo 3 */}
      <div className="col-4">
        <label className="form-label">Chat Page Photo</label>
        <input
          className="form-control"
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => handlePhotoSelect("C", e.target.files)}
        />
        <div className="form-text">
          To preserve the old photo, don&apos;t select anything here...
        </div>
      </div>

      {/* Bio */}
      <div className="col-12">
        <label className="form-label">Bio</label>
        <textarea
          className="form-control"
          rows={4}
          value={currentUser.bio}
          onChange={(e) => updateCurrentUser({ bio: e.target.value })}
        />
      </div>

      {/* DIVIDER */}
      <hr className="mt-4 mb-0"></hr>
      <div className="col-12">
        <h3>Questions</h3>
      </div>

      {/* Questions */}
      {Object.keys(questions).map((questionSlug) => {
        const questionText = questions[questionSlug];
        const usersAnswer = currentUser.answers
          ? currentUser.answers[questionSlug]
          : "";
        return (
          <div className="col-6" key={questionSlug}>
            <label className="form-label">{questionText}</label>
            <input
              type="text"
              className="form-control"
              value={usersAnswer}
              onChange={(e) => updateUsersAnswer(questionSlug, e.target.value)}
            />
          </div>
        );
      })}

      {/* DIVIDER */}
      <hr className="mt-4 mb-0"></hr>
      <div className="col-12">
        <h3>Connections</h3>
      </div>

      {/* Connections List */}
      {Object.keys(userList)
        .filter((userSlug) => userSlug !== slug)
        .map((connectionSlug) => {
          const connection = userList[connectionSlug];
          return (
            <div className="col-3" key={connectionSlug}>
              <div className="form-check">
                <input
                  id={`${connectionSlug}Checkbox`}
                  className="form-check-input"
                  type="checkbox"
                  checked={
                    currentUser.connections &&
                    currentUser.connections[connectionSlug]
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      addUsersConnection(connectionSlug);
                    } else {
                      removeUsersConnection(connectionSlug);
                    }
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor={`${connectionSlug}Checkbox`}
                  style={{
                    padding: "0 4px",
                    borderRadius: "6px",
                    color:
                      connection.type === "primary"
                        ? "white"
                        : getHexCode(connection.theme),
                    backgroundColor:
                      connection.type === "primary"
                        ? getHexCode(connection.theme)
                        : "white",
                    fontWeight: connection.type === "primary" ? 800 : 400,
                  }}
                >
                  {getQualifiedName(connectionSlug, connection)}
                </label>
              </div>
            </div>
          );
        })}

      {/* Buttons */}
      <hr className="mt-4"></hr>
      <div className="col-12">
        <button
          type="submit"
          className="btn btn-success me-2"
          disabled={isLoading}
        >
          {isLoading ? "Please Wait..." : "Save"}
        </button>
        <button
          type="button"
          className="btn btn-secondary me-2"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
