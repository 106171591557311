import { useMemo, useRef, useState } from "react";

import { firebaseDB } from "src/utils/firebase";
import { ref, set, update } from "firebase/database";

import { HiddenNavigation } from "../Common/HiddenNavigation";

import { Page } from "src/models/Page";
import { getFullName, User } from "src/models/User";
import { getGradientImage, getHexCode } from "src/models/Theme";
import { ChatRequest, ChatRequestStatus } from "src/models/ChatRequest";

import { store } from "src/store";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { setCurrentPage, setUserToShow } from "src/store/users";

import "./ChatRequests.scoped.scss";

interface PropTypes {
  list: ChatRequest[];
}

export const ChatRequests = (props: PropTypes) => {
  const { list: chatRequests } = props;

  const dispatch = useAppDispatch();

  // Loading flag
  const isLoading = useRef(false);

  // User data
  const userList = useAppSelector(
    (state) => state.users.list as Record<string, User>
  );
  const authUser = useAppSelector((state) => state.users.authUser as User);
  const authUserSlug = useAppSelector(
    (state) => state.users.authUserSlug as string
  );

  // Current chat request to show on screen
  const [currentRequestID, setCurrentRequestID] = useState<string | number>();

  const currentRequest = useMemo(() => {
    if (chatRequests.length === 1) {
      return chatRequests[0];
    } else if (chatRequests.length > 1 && currentRequestID !== undefined) {
      return chatRequests.filter((r) => r.timestamp === currentRequestID)[0];
    }
  }, [chatRequests, currentRequestID]);

  const currentRequestIcon = useMemo(() => {
    switch (currentRequest?.status) {
      case "P":
        if (currentRequest.from === authUserSlug) {
          return "/images/icons/request_pending_white.png";
        } else {
          return "/images/icons/request_pending_4d4d4d.png";
        }
      case "R":
        return "/images/icons/request_rejected_cccccc.png";
      case "A":
        return "/images/icons/request_white.png";
    }
  }, [currentRequest, authUserSlug]);

  const currentRequestTextColor = useMemo(() => {
    switch (currentRequest?.status) {
      case "P":
        if (currentRequest.from === authUserSlug) {
          return "white";
        } else {
          return "#4d4d4d";
        }
      case "R":
        return "#cccccc";
      case "A":
        return "white";
    }
  }, [currentRequest, authUserSlug]);

  const currentRequestBgColor = useMemo(() => {
    switch (currentRequest?.status) {
      case "P":
        if (currentRequest.from === authUserSlug) {
          return getHexCode(authUser.theme);
        } else {
          return "white";
        }
      case "R":
        return "white";
      case "A":
        return getHexCode(authUser.theme);
    }
  }, [currentRequest, authUserSlug, authUser]);

  const currentRequestHasButtons = useMemo(() => {
    switch (currentRequest?.status) {
      case "P":
        if (currentRequest.from === authUserSlug) {
          return false;
        } else {
          return true;
        }
      case "R":
        return false;
      case "A":
        return false;
    }
  }, [currentRequest, authUserSlug]);

  const requestListTextColor = (request: ChatRequest) => {
    switch (request.status) {
      case "P":
        if (request.from === authUserSlug) {
          return getHexCode(authUser.theme);
        } else {
          return "#4d4d4d";
        }
      case "R":
        return "#cccccc";
      case "A":
        return getHexCode(authUser.theme);
    }
  };

  const requestText = (request: ChatRequest | undefined) => {
    switch (request?.status) {
      case "P":
        if (request.from === authUserSlug) {
          return `Waiting for ${getFullName(userList[request.to])} to chat`;
        } else {
          return `${getFullName(userList[request.from])} wants to chat`;
        }
      case "R":
        return `${getFullName(userList[request.to])} is unavailable to chat`;
      case "A":
        return `${getFullName(userList[request.to])} is ready to chat`;
    }
  };

  // For request list
  const pendingIncomingRequests = useMemo<ChatRequest[]>(() => {
    return chatRequests.filter(
      (r) => r.status === "P" && r.from !== authUserSlug
    );
  }, [chatRequests, authUserSlug]);

  const rejectedOutgoingRequests = useMemo<ChatRequest[]>(() => {
    return chatRequests.filter(
      (r) => r.status === "R" && r.from === authUserSlug
    );
  }, [chatRequests, authUserSlug]);

  const pendingOutgoingRequests = useMemo<ChatRequest[]>(() => {
    return chatRequests.filter(
      (r) => r.status === "P" && r.from === authUserSlug
    );
  }, [chatRequests, authUserSlug]);

  const requestsToShow = useMemo(() => {
    return [
      ...pendingIncomingRequests,
      ...rejectedOutgoingRequests,
      ...pendingOutgoingRequests,
    ];
  }, [
    pendingIncomingRequests,
    rejectedOutgoingRequests,
    pendingOutgoingRequests,
  ]);

  // Navigation
  const handleBackNavigation = () => {
    // Move from list back to single view
    if (chatRequests.length > 1 && currentRequestID) {
      setCurrentRequestID(undefined);
      return;
    }
    // Handle back navigation for single view
    if (currentRequest && currentRequest.status !== "P") {
      const navigateToChatPageAfterUpdate = currentRequest.status === "A";
      if (!isLoading.current) {
        isLoading.current = true;
        set(
          ref(firebaseDB, `chatRequests/${currentRequest.timestamp}/status`),
          `${currentRequest.status}S`
        )
          .catch((error) => {
            alert(JSON.stringify(error));
          })
          .then(() => {
            isLoading.current = false;

            if (navigateToChatPageAfterUpdate) {
              dispatch(setUserToShow(currentRequest.to));
              dispatch(setCurrentPage(Page.Chat));
            }
          });
      }
    }
  };

  const handleChatRequestClick = (request: ChatRequest) => {
    setCurrentRequestID(request.timestamp);
  };

  const handleCurrentRequestAccept = () => {
    if (currentRequest && !isLoading.current) {
      isLoading.current = true;

      const updates: Record<string, ChatRequestStatus> = {};

      const allChatRequests = store.getState().chatRequests.list;
      const acceptingRequestOf = currentRequest.from;

      Object.keys(allChatRequests).forEach((chatRequestId) => {
        const chatRequest = allChatRequests[chatRequestId];
        // Mark 'current request' as accepted
        if (chatRequestId == currentRequest.timestamp + "") {
          updates[`chatRequests/${chatRequestId}/status`] = "A";
        } else {
          // Close all other requests that are related to me
          if (
            chatRequest.from === authUserSlug ||
            chatRequest.to === authUserSlug
          ) {
            switch (chatRequest.status) {
              case "P":
                if (chatRequest.from === authUserSlug) {
                  updates[`chatRequests/${chatRequestId}/status`] = "X";
                } else {
                  updates[`chatRequests/${chatRequestId}/status`] = "R";
                }
                break;

              case "R":
                if (chatRequest.from === authUserSlug) {
                  updates[`chatRequests/${chatRequestId}/status`] = "RS";
                }
                break;

              case "A":
                updates[`chatRequests/${chatRequestId}/status`] = "XXX";
                break;
            }
          }
          // Close all requests related to the other person
          if (
            chatRequest.from === acceptingRequestOf ||
            chatRequest.to === acceptingRequestOf
          ) {
            switch (chatRequest.status) {
              case "P":
                if (chatRequest.from === acceptingRequestOf) {
                  updates[`chatRequests/${chatRequestId}/status`] = "X";
                } else {
                  updates[`chatRequests/${chatRequestId}/status`] = "R";
                }
                break;

              case "R":
                if (chatRequest.from === acceptingRequestOf) {
                  updates[`chatRequests/${chatRequestId}/status`] = "RS";
                }
                break;

              case "A":
                updates[`chatRequests/${chatRequestId}/status`] = "XXX";
                break;
            }
          }
        }
      });

      // Update on Firebase
      update(ref(firebaseDB), updates)
        .catch((error) => {
          alert(JSON.stringify(error));
        })
        .then(() => {
          isLoading.current = false;

          // Go to chat page
          dispatch(setUserToShow(currentRequest.from));
          dispatch(setCurrentPage(Page.Chat));
        });
    }
  };

  const handleCurrentRequestReject = () => {
    if (currentRequest && !isLoading.current) {
      isLoading.current = true;
      set(
        ref(firebaseDB, `chatRequests/${currentRequest.timestamp}/status`),
        "R"
      )
        .catch((error) => {
          alert(JSON.stringify(error));
        })
        .then(() => {
          setCurrentRequestID(undefined);
          isLoading.current = false;
        });
    }
  };

  return (
    <div
      className="mc-chatreqs"
      style={{
        background: `url("${getGradientImage(
          authUser.theme
        )}") no-repeat center center`,
      }}
    >
      {currentRequest ? (
        // SINGLE NOTIFICATION VIEW

        <div
          className="mc-chatreqs__card mc-chatreqs__single shadow-lg"
          style={{
            color: currentRequestTextColor,
            backgroundColor: currentRequestBgColor,
          }}
        >
          {/* Icon */}
          <img className="mc-chatreqs__single-icon" src={currentRequestIcon} />
          {/* Text */}
          <div className="mc-chatreqs__single-text">
            {requestText(currentRequest)}
          </div>
          {/* Buttons */}
          {currentRequestHasButtons && (
            <div className="mc-chatreqs__single-buttons">
              {/* Accept Button */}
              <img
                src="/images/buttons/accept.png"
                onClick={handleCurrentRequestAccept}
              />
              {/* Reject Button */}
              <img
                src="/images/buttons/reject.png"
                onClick={handleCurrentRequestReject}
              />
            </div>
          )}
        </div>
      ) : (
        // NOTIFICATION LIST VIEW

        <div className="mc-chatreqs__list-wrapper">
          {/* Logo */}
          <img
            className="mc-chatreqs__list-logo"
            src="/images/icons/request_white.png"
          />
          {/* List */}
          <div className="mc-chatreqs__card mc-chatreqs__list shadow-lg">
            {requestsToShow.map((request, index) => {
              return (
                <div
                  key={request.timestamp}
                  className="mc-chatreqs__list-item"
                  style={{ color: requestListTextColor(request) }}
                  onClick={() => handleChatRequestClick(request)}
                >
                  {/* Text */}
                  {requestText(request)}

                  {/* Line */}
                  {index < requestsToShow.length - 1 && (
                    <div className="mc-chatreqs__line" />
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}

      <HiddenNavigation onLeftClick={handleBackNavigation} />
    </div>
  );
};
