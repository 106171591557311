import { useMemo } from "react";

import { getProfilePhotoURL, User } from "src/models/User";

import {
  selectChattingWith,
  selectIsChattingWithSomeone,
} from "src/store/chat-requests";
import { useAppSelector } from "src/hooks/useAppSelector";

import "./Connection.scoped.scss";

interface PropTypes {
  className?: string;
  size?: "sm" | "md" | "lg" | "xl";
  user: User;
  userSlug: string;
  onVideoCallClick?: () => void;
  onProfileClick?: () => void;
  onChatClick?: () => void;
}

export const Connection = ({
  className,
  size,
  user,
  userSlug,
  onVideoCallClick,
  onProfileClick,
  onChatClick,
}: PropTypes) => {
  const settings = useAppSelector((state) => state.settings.value);

  const allChatRequests = useAppSelector((state) => state.chatRequests.list);

  const isThisConnectionChattingWithSomeone = useMemo(() => {
    const allChatRequestsIds = Object.keys(allChatRequests);
    for (let i = 0; i < allChatRequestsIds.length; i++) {
      const currentChatRequestId = allChatRequestsIds[i];
      const currentChatRequest = allChatRequests[currentChatRequestId];
      if (
        currentChatRequest.from == userSlug ||
        currentChatRequest.to == userSlug
      ) {
        if (
          currentChatRequest.status === "A" ||
          currentChatRequest.status === "AS"
        ) {
          return true;
        }
      }
    }
    return false;
  }, [userSlug, allChatRequests]);

  const chatRequestSystemEnabled = useAppSelector(
    (state) => state.settings.value.chatRequestSystem
  );

  const currentlyChattingWith = useAppSelector(selectChattingWith);
  const isAuthUserChattingWithSomeone = useAppSelector(
    selectIsChattingWithSomeone
  );

  const isChatButtonVisible = useMemo(() => {
    if (settings.connectionChat === "F") {
      return false;
    }
    if (chatRequestSystemEnabled === "F") {
      return true;
    }
    if (isAuthUserChattingWithSomeone) {
      if (currentlyChattingWith === userSlug) {
        return true;
      } else {
        return false;
      }
    }
    if (isThisConnectionChattingWithSomeone) {
      return false;
    }
    return true;
  }, [
    settings,
    userSlug,
    chatRequestSystemEnabled,
    currentlyChattingWith,
    isAuthUserChattingWithSomeone,
    isThisConnectionChattingWithSomeone,
  ]);

  return (
    <div
      className={`mc-connection
        mc-connection--${size ? size : "md"}
        ${className ? className : ""}
        shadow`}
      style={{
        background: `url("${getProfilePhotoURL(
          user
        )}") center center no-repeat, #f2f2f2`,
      }}
    >
      {/* Ring & Name */}
      <div className="mc-connection__ring">
        <div className="mc-connection__textarea">
          <div className="mc-connection__text">{user.firstName}</div>
        </div>
      </div>

      {/* Buttons */}
      <div className="mc-connection__buttons">
        {/* Video Call Button */}
        {settings.connectionVideoCalls === "T" && (
          <div
            className="mc-connection__button shadow"
            onClick={onVideoCallClick}
          >
            <img src="/images/icons/icon_video.png" />
          </div>
        )}

        {/* Profile Button */}
        {settings.connectionProfile === "T" && (
          <div
            className="mc-connection__button shadow"
            onClick={onProfileClick}
          >
            <img src="/images/icons/icon_profile.png" />
          </div>
        )}

        {/* Chat Button */}
        {isChatButtonVisible && (
          <div className="mc-connection__button shadow" onClick={onChatClick}>
            <img src="/images/icons/icon_chat.png" />
          </div>
        )}
      </div>
    </div>
  );
};
