import Twemoji from "react-twemoji";
import { ReactNode } from "react";
import { getLightHexCode, Theme } from "src/models/Theme";

import "./Message.scoped.scss";
import "./Message.scss";

interface PropTypes {
  className?: string;
  theme?: Theme;
  children?: ReactNode;
}

export const Message = ({ className, theme, children }: PropTypes) => {
  return (
    <div
      className={`mc-message ${className ? className : ""} shadow`}
      style={{
        border: `4px solid ${theme ? getLightHexCode(theme) : "white"}`,
      }}
    >
      <Twemoji options={{ className: "mc-message__emoji" }}>{children}</Twemoji>
    </div>
  );
};
