import isEmpty from "lodash.isempty";
import { useNavigate } from "react-router-dom";

import { AdminLogin } from "src/components/Admin/Login";
import { Loading } from "src/components/Layout/Loading";
import { useAppSelector } from "src/hooks/useAppSelector";

export const UserList = () => {
  // Get users list
  const navigate = useNavigate();
  const userList = useAppSelector((state) => state.users.list);

  // Auth
  const isAdmin = useAppSelector((state) => state.users.isAdmin);
  if (!isAdmin) {
    return <AdminLogin />;
  }

  // Show loading if required
  if (isEmpty(userList)) {
    return <Loading />;
  }

  // Function
  const handleUserEditClick = (slug: string) => {
    navigate(`/admin/users/${slug}`);
  };

  // Return users table
  return (
    <div className="m-3">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name (Internal Use)</th>
            <th scope="col">Display Name on App</th>
            <th scope="col">Gender</th>
            <th scope="col">Type</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(userList).map((userSlug) => {
            const user = userList[userSlug];
            return (
              <tr key={user.firstName}>
                <td className={user.type === "primary" ? "fw-bold" : ""}>
                  {userSlug.charAt(0).toUpperCase() + userSlug.slice(1)}
                </td>
                <td className={user.type === "primary" ? "fw-bold" : ""}>
                  {user.firstName} {user.lastName ? user.lastName : ""}
                </td>
                <td>{user.gender}</td>
                <td>{user.type === "primary" ? "Single" : "Connection"}</td>
                <td>
                  <button
                    className="btn btn-success"
                    onClick={() => handleUserEditClick(userSlug)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
