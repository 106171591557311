import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";

import { ref, set } from "firebase/database";
import { firebaseDB } from "src/utils/firebase";
import { useAppSelector } from "src/hooks/useAppSelector";

import { AdminLogin } from "../Login";
import { Loading } from "src/components/Layout/Loading";

import { getQualifiedName } from "src/models/User";
import { Alert, AlertStatus } from "src/models/Alert";

export const SendAlert = () => {
  const navigate = useNavigate();

  // User data
  const userList = useAppSelector((state) => state.users.list); // Get all users

  // Alert object
  const [myAlert, setMyAlert] = useState<Alert>({
    timestamp: new Date().getTime(),
    message: "",
    status: {},
  });

  // Helper functions
  const updateMyAlert = (partialData: Partial<Alert>) => {
    setMyAlert({ ...myAlert, ...partialData });
  };

  const updateMyAlertStatus = (userSlug: string, status: AlertStatus) => {
    setMyAlert({
      ...myAlert,
      status: {
        ...myAlert.status,
        [userSlug]: status,
      },
    });
  };

  // Set default status as yes
  useEffect(() => {
    const userSlugs = Object.keys(userList);
    if (userSlugs.length > 0) {
      const defaultStatus: { [userSlug: string]: AlertStatus } = {};
      userSlugs.forEach((userSlug) => {
        defaultStatus[userSlug] = "Y";
      });
      setMyAlert((myAlert) => {
        return { ...myAlert, status: defaultStatus };
      });
    }
  }, [userList]);

  // Submit form
  const [isLoading, setLoading] = useState(false);
  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault(); // Stop the page refresh nonsense
    if (myAlert.message.trim() !== "") {
      setLoading(true);
      set(ref(firebaseDB, "alerts/" + myAlert.timestamp), myAlert)
        .then(() => {
          setLoading(false);
          navigate(-1);
        })
        .catch((error) => {
          setLoading(false);
          alert(JSON.stringify(error));
        });
    } else {
      alert("Type something plz! -_-");
    }
  };

  // Auth
  const isAdmin = useAppSelector((state) => state.users.isAdmin);
  if (!isAdmin) {
    return <AdminLogin />;
  }

  // Show loading if required
  if (isEmpty(userList)) {
    return <Loading />;
  }

  // JSX
  return (
    <form className="row m-3 g-3" onSubmit={handleSubmit}>
      {/* Title */}
      <div className="col-12">
        <h3>Create Notification</h3>
      </div>

      {/* Message */}
      <div className="col-12">
        <label className="form-label">Message</label>
        <textarea
          className="form-control"
          rows={4}
          value={myAlert.message}
          onChange={(e) => updateMyAlert({ message: e.target.value })}
        />
      </div>

      {/* DIVIDER */}
      <hr className="mt-4 mb-0"></hr>
      <div className="col-12">
        <h5>Show To</h5>
      </div>

      {/* User List */}
      {Object.keys(userList).map((userSlug) => {
        const user = userList[userSlug];
        return (
          <div className="col-3" key={userSlug}>
            <label className="form-label">
              {getQualifiedName(userSlug, user)}
            </label>
            <select
              className="form-select"
              value={myAlert.status[userSlug]}
              onChange={(e) =>
                updateMyAlertStatus(userSlug, e.target.value as AlertStatus)
              }
              required
            >
              <option value="N">No</option>
              <option value="Y">Yes</option>
            </select>
          </div>
        );
      })}

      {/* Buttons */}
      <hr className="mt-4"></hr>
      <div className="col-12">
        {/* Save Button */}
        <button
          type="submit"
          className="btn btn-success me-2"
          disabled={isLoading}
        >
          {isLoading ? "Please Wait..." : "Save"}
        </button>

        {/* Cancel Button */}
        <button
          type="button"
          className="btn btn-secondary me-2"
          onClick={() => navigate(-1)}
        >
          Cancel
        </button>
      </div>
    </form>
  );
};
