import { useMemo, useState } from "react";
import { useAppSelector } from "src/hooks/useAppSelector";

import { getFullName, User } from "src/models/User";
import { VideoRequest } from "src/models/VideoRequest";
import { getDoodleImage, getGradientImage, getHexCode } from "src/models/Theme";
import { HiddenNavigation } from "src/components/User/Common/HiddenNavigation";

import { ref, set } from "firebase/database";
import { firebaseDB } from "src/utils/firebase";

import "./VideoRequests.scoped.scss";

interface PropTypes {
  data: VideoRequest;
}

export const VideoRequests = ({ data: videoRequest }: PropTypes) => {
  // User
  const userList = useAppSelector(
    (state) => state.users.list as Record<string, User>
  );
  const authUser = useAppSelector((state) => state.users.authUser as User);
  const authUserSlug = useAppSelector(
    (state) => state.users.authUserSlug as string
  );

  // Display data
  const isToMe = useMemo(() => {
    return videoRequest.to === authUserSlug;
  }, [authUserSlug, videoRequest]);

  const titleText = useMemo(() => {
    switch (videoRequest.status) {
      case "P":
        if (isToMe) {
          return "Video Call";
        } else {
          return "Waiting";
        }

      case "A":
        return "Starting Call";

      case "R":
        return "Declined";

      default:
        return "Video Call";
    }
  }, [isToMe, videoRequest]);

  const displaySlug = useMemo(() => {
    if (isToMe) {
      return videoRequest.from;
    } else {
      return videoRequest.to;
    }
  }, [isToMe, videoRequest]);

  const displayName = useMemo(() => {
    return getFullName(userList[displaySlug]);
  }, [userList, displaySlug]);

  const displayImage = useMemo(() => {
    return userList[displaySlug].photoURL;
  }, [userList, displaySlug]);

  const isAcceptVisible = useMemo(() => {
    return videoRequest.status === "P" && isToMe;
  }, [isToMe, videoRequest]);

  const isRejectVisible = useMemo(() => {
    return videoRequest.status === "P";
  }, [videoRequest]);

  // Click Functions

  const [isLoading, setLoading] = useState(false);

  const handleAcceptClick = () => {
    if (!isLoading) {
      setLoading(true);
      set(
        ref(firebaseDB, `videoRequests/${videoRequest.timestamp}/status`),
        "A"
      )
        .catch((error) => {
          alert(JSON.stringify(error));
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  const handleRejectClick = () => {
    if (!isLoading) {
      setLoading(true);
      set(
        ref(firebaseDB, `videoRequests/${videoRequest.timestamp}/status`),
        isToMe ? "R" : "X"
      )
        .catch((error) => {
          alert(JSON.stringify(error));
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  const handleBackNavigations = () => {
    if (!isLoading && videoRequest.status !== "P") {
      setLoading(true);
      set(
        ref(firebaseDB, `videoRequests/${videoRequest.timestamp}/status`),
        `${videoRequest.status}S`
      )
        .catch((error) => {
          alert(JSON.stringify(error));
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  // JSX
  return (
    <div
      className="mc-vidreqs"
      style={{
        background: `url("${getGradientImage(
          authUser.theme
        )}") no-repeat center center`,
      }}
    >
      {/* Doodles */}
      <div
        className="mc-vidreqs__doodle"
        style={{
          background: `url("${getDoodleImage(authUser.theme)}")`,
        }}
      />

      {/* Card */}
      <div className="mc-vidreqs__card shadow-lg">
        {/* Text & Image */}
        <div className="mc-vidreqs__title text-uppercase">{titleText}</div>
        <div
          className="mc-vidreqs__image"
          style={{
            background: getHexCode(authUser.theme),
          }}
        >
          <div
            className="mc-vidreqs__image-inner"
            style={{
              background: `url("${displayImage}") no-repeat center center`,
            }}
          />
        </div>
        <div className="mc-vidreqs__name text-uppercase">{displayName}</div>

        {/* Buttons */}
        <div className="mc-vidreqs__buttons">
          {/* Reject */}
          {isRejectVisible && (
            <img
              className="mc-vidreqs__button"
              src="/images/buttons/call_reject.png"
              onClick={handleRejectClick}
            />
          )}
          {/* Answer */}
          {isAcceptVisible && (
            <img
              className="mc-vidreqs__button"
              src="/images/buttons/call_answer.png"
              onClick={handleAcceptClick}
            />
          )}
        </div>
      </div>

      {/* Navigation */}
      <HiddenNavigation onLeftClick={handleBackNavigations} />
    </div>
  );
};
