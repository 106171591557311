import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import isEmpty from "lodash.isempty";

import { Chat } from "../Chat";
import { Alert } from "../Alert";
import { Login } from "../Login";
import { Albums } from "../Albums";
import { Profile } from "../Profile";
import { Questions } from "../Questions";
import { Connections } from "../Connections";
import { ChatRequests } from "../ChatRequests";
import { VideoRequests } from "../VideoRequests";

import { Page } from "src/models/Page";
import { User } from "src/models/User";

import { Loading } from "src/components/Layout/Loading";
import { NotFound } from "src/components/Layout/NotFound";

import { selectMyOpenAlerts } from "src/store/alerts";
import { setAuthUser, setUserToShow } from "src/store/users";
import { selectMyOpenChatRequests } from "src/store/chat-requests";

import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { selectMyOpenVideoRequests } from "src/store/video-requests";

export const UserContainer = () => {
  // Authentication
  const { slug } = useParams();
  const [isInitialized, setInitialized] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isAdmin = useAppSelector((state) => state.users.isAdmin);

  // Redux
  const dispatch = useAppDispatch();
  const userList = useAppSelector((state) => state.users.list);
  const currentPage = useAppSelector((state) => state.users.currentPage);
  const authUser = useAppSelector((state) => state.users.authUser);
  const authUserSlug = useAppSelector((state) => state.users.authUserSlug);
  const userToShow = useAppSelector((state) => state.users.userToShow);

  // Requests
  const myOpenAlerts = useAppSelector(selectMyOpenAlerts);
  const myOpenVideoRequests = useAppSelector(selectMyOpenVideoRequests);
  const myOpenChatRequests = useAppSelector(selectMyOpenChatRequests);

  // Initialization
  useEffect(() => {
    if (!isEmpty(userList) && !isInitialized) {
      dispatch(setAuthUser(slug));
      dispatch(setUserToShow(slug));
      setInitialized(true);
    }
  }, [userList, isInitialized, slug, dispatch]);

  // Data validation
  if (isEmpty(userList) || !isInitialized) {
    return <Loading />;
  }
  if (!authUser || !userToShow) {
    return <NotFound />;
  }
  if (!isAdmin && !isLoggedIn) {
    return (
      <Login
        user={authUser as User}
        userSlug={authUserSlug as string}
        onVerified={() => setIsLoggedIn(true)}
      />
      // Added 'as' here since validation is already done before this
    );
  }

  // Show alerts if present
  if (myOpenAlerts.length > 0) {
    return <Alert data={myOpenAlerts[0]} />;
  }

  // Show vid reqs if present
  if (myOpenVideoRequests.length > 0) {
    return <VideoRequests data={myOpenVideoRequests[0]} />;
  }

  // Show chat reqs if present
  if (myOpenChatRequests.length > 0) {
    return <ChatRequests list={myOpenChatRequests} />;
  }

  // Find the right component
  switch (currentPage) {
    case Page.Profile:
      return <Profile />;

    case Page.Albums:
      return <Albums />;

    case Page.Questions:
      return <Questions />;

    case Page.Connections:
      return <Connections />;

    case Page.Chat:
      return <Chat />;

    default:
      return <NotFound />;
  }
};
