import { CSSProperties } from "react";
import { getMirrorGradientImage, Theme } from "src/models/Theme";

import "./Frame.scoped.scss";

interface PropTypes {
  className?: string;
  style?: CSSProperties;
  theme?: Theme;
  src?: string;
}

export const Frame = (props: PropTypes) => {
  const { className, style, theme, src } = props;

  return (
    <div
      className={`mc-frame ${className ? className : ""}`}
      style={{
        ...style,
        background: theme
          ? `url("${getMirrorGradientImage(theme)}"), white`
          : "white",
      }}
    >
      <div
        className="mc-frame__inner"
        style={{
          background: src
            ? `url("${src}")no-repeat center center, #f2f2f2`
            : "#f2f2f2",
        }}
      ></div>
    </div>
  );
};
