export type ChatRequestStatus =
  | "P"
  | "A"
  | "AS"
  | "R"
  | "RS"
  | "X"
  | "XX"
  | "XXX";
// [P]ending | [A]ccepted | [A]ccepted & [S]een | [R]ejected | [R]ejected and [S]een
// [X] Cancelled | [XX] Closed by Admin | [XXX] Closed by Automation due to conflict

export interface ChatRequest {
  from: string;
  to: string;
  status: ChatRequestStatus;
  timestamp: number;
}

export const getChatRequestStatusName = (status: ChatRequestStatus) => {
  switch (status) {
    case "A":
    case "AS":
      return "Chatting";

    case "R":
    case "RS":
      return "Rejected";

    case "P":
      return "Pending";

    case "X":
    case "XXX":
      return "Cancelled";
  }
};

export const getChatRequestStatusColor = (status: ChatRequestStatus) => {
  switch (status) {
    case "A":
    case "AS":
      return "success";

    case "R":
    case "RS":
      return "danger";

    case "P":
      return "warning";

    case "X":
    case "XXX":
      return "black";
  }
};
