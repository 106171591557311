import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Alert } from "src/models/Alert";
import { RootState } from ".";

export interface AlertState {
  list: Record<string, Alert>;
}

const initialState: AlertState = {
  list: {},
};

export const alertsSlice = createSlice({
  name: "alerts",
  initialState,
  reducers: {
    setAlerts: (state, action: PayloadAction<Record<string, Alert>>) => {
      state.list = action.payload;
    },
    clearAlerts: (state) => {
      state.list = {};
    },
  },
});

const selectAuthUserSlug = (state: RootState) => state.users.authUserSlug;
const selectAllAlerts = (state: RootState) => state.alerts.list;

export const selectMyOpenAlerts = createSelector(
  selectAuthUserSlug,
  selectAllAlerts,
  (authUserSlug, allAlerts) => {
    const myAlerts: Alert[] = [];
    Object.keys(allAlerts).forEach((alertId) => {
      if (authUserSlug && allAlerts[alertId].status[authUserSlug] === "Y") {
        myAlerts.push(allAlerts[alertId]);
      }
    });
    return myAlerts;
  }
);

export const { setAlerts, clearAlerts } = alertsSlice.actions;

export const alertsReducer = alertsSlice.reducer;
