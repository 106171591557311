import { useState } from "react";

import { User } from "src/models/User";
import {
  getHexCode,
  getGradientImage,
  getMirrorGradientImage,
} from "src/models/Theme";
import { Alert as IAlert } from "src/models/Alert";

import { ref, set } from "firebase/database";
import { firebaseDB } from "src/utils/firebase";

import { useAppSelector } from "src/hooks/useAppSelector";
import { HiddenNavigation } from "../Common/HiddenNavigation";

import "./Alert.scoped.scss";

interface PropTypes {
  data: IAlert;
}

export const Alert = ({ data: myAlert }: PropTypes) => {
  // User
  const authUser = useAppSelector((state) => state.users.authUser as User);
  const authUserSlug = useAppSelector(
    (state) => state.users.authUserSlug as string
  );

  // Close the alert
  const [isLoading, setLoading] = useState(false);
  const handleCloseAlert = () => {
    if (!isLoading) {
      set(
        ref(firebaseDB, `alerts/${myAlert.timestamp}/status/${authUserSlug}`),
        "X"
      )
        .catch((error) => {
          alert(JSON.stringify(error));
        })
        .then(() => {
          setLoading(false);
        });
    }
  };

  // JSX
  return (
    <div
      className="mc-alert"
      style={{
        background: `url("${getGradientImage(
          authUser.theme
        )}") no-repeat center center`,
      }}
    >
      {/* Card */}
      <div className="mc-alert__card shadow">
        {/* Logo */}
        <img className="mc-alert__logo" src="/images/brand/icon.png" />

        {/* Text */}
        <div className="mc-alert__text">{myAlert.message}</div>
      </div>

      <HiddenNavigation onLeftClick={handleCloseAlert} />
    </div>
  );
};
