import { Page } from "src/models/Page";
import { getChatPhotoURL, User } from "src/models/User";
import { getChatDoodleImage, getGradientImage } from "src/models/Theme";

import { MessageBox } from "./MessageBox";
import { MessageList } from "./MessageList";

import { Frame } from "../Common/Frame";
import { HiddenNavigation } from "../Common/HiddenNavigation";

import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { setCurrentPage, setUserToShow } from "src/store/users";

import "./Chat.scoped.scss";
import "./Chat.scss";

export const Chat = () => {
  // Redux
  const dispatch = useAppDispatch();
  const authUser = useAppSelector((state) => state.users.authUser as User);
  const authUserSlug = useAppSelector(
    (state) => state.users.authUserSlug as string
  );
  const userToShow = useAppSelector((state) => state.users.userToShow as User);
  const userToShowSlug = useAppSelector(
    (state) => state.users.userToShowSlug as string
  );

  // Functions
  const handleBackNavigation = () => {
    dispatch(setUserToShow(authUserSlug));
    dispatch(setCurrentPage(Page.Connections));
  };

  // JSX
  return (
    <div
      className="mc-chat"
      style={{
        background: `url("${getGradientImage(
          authUser.theme
        )}") no-repeat center center`,
      }}
    >
      {/* Background */}
      <div className="mc-chat__left-bg">
        <img src={getChatDoodleImage("W")} />
      </div>
      <div className="mc-chat__right-bg">
        <img src={getChatDoodleImage("W")} />
      </div>

      {/* Content */}
      <div className="mc-chat__main">
        {/* User Photo */}
        <Frame
          className="mc-chat__myphoto shadow"
          theme={authUser.theme}
          src={getChatPhotoURL(authUser)}
        />

        {/* Message Area */}
        <div className="mc-chat__message-area">
          <MessageList
            className="mc-chat__message-list"
            theme={authUser.theme}
            senderSlug={authUserSlug}
            receiverSlug={userToShowSlug}
          />
          <MessageBox
            className="mc-chat__message-box"
            senderSlug={authUserSlug}
            receiverSlug={userToShowSlug}
          />
        </div>

        {/* Samne Wale Ka Photo */}
        <Frame
          className="mc-chat__theirphoto"
          src={getChatPhotoURL(userToShow)}
        />
      </div>

      {/* Icon */}
      <div className="mc-chat__icon">
        <img src="/images/brand/icon_white_shadow.png" />
      </div>

      {/* Hidden Buttons */}
      <HiddenNavigation onLeftClick={handleBackNavigation} />
    </div>
  );
};
