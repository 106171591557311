import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { Page } from "src/models/Page";
import { User } from "src/models/User";

export interface UsersState {
  list: Record<string, User>;
  isAdmin: boolean;
  authUser?: User;
  authUserSlug?: string;
  userToShow?: User; // For profile page, questions page, etc
  userToShowSlug?: string;
  currentPage: Page;
}

const initialState: UsersState = {
  list: {},
  isAdmin: false,
  currentPage: Page.Profile,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setUsersList: (state, action: PayloadAction<Record<string, User>>) => {
      state.list = action.payload;
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.isAdmin = action.payload;
    },
    setAuthUser: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.authUserSlug = action.payload;
        state.authUser = state.list[action.payload];
        if (state.authUser) {
          document.title = state.authUser.firstName;
          if (state.authUser.lastName) {
            document.title = document.title + " " + state.authUser.lastName;
          }
        }
      }
    },
    setUserToShow: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload) {
        state.userToShowSlug = action.payload;
        state.userToShow = state.list[action.payload];
      }
    },
    setCurrentPage: (state, action: PayloadAction<Page>) => {
      state.currentPage = action.payload;
    },
    goToAlbumsPage: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload !== undefined) {
        usersSlice.caseReducers.setUserToShow(
          state,
          action as PayloadAction<string>
        );
      }
      state.currentPage = Page.Albums;
    },
    goToQuestionsPage: (state, action: PayloadAction<string | undefined>) => {
      if (action.payload !== undefined) {
        usersSlice.caseReducers.setUserToShow(
          state,
          action as PayloadAction<string>
        );
      }
      state.currentPage = Page.Questions;
    },
    goToConnectionsPage: (state) => {
      state.currentPage = Page.Connections;
    },
  },
});

export const {
  setUsersList,
  setIsAdmin,
  setAuthUser,
  setUserToShow,
  setCurrentPage,
  goToAlbumsPage,
  goToQuestionsPage,
  goToConnectionsPage,
} = usersSlice.actions;

export const usersReducer = usersSlice.reducer;
