// Album Names/Covers

export interface Album {
  slug: string;
  name: string;
}

export interface AlbumList {
  [key: string]: Album[];
}

export const alumbs: AlbumList = {
  // Main
  ananya: [
    {
      slug: "friends",
      name: "Friends Are Family",
    },
    {
      slug: "moods",
      name: "Moods",
    },
    {
      slug: "selfie",
      name: "Selfie Mode",
    },
    {
      slug: "travel",
      name: "Travel",
    },
  ],
  chiragg: [
    {
      slug: "suit",
      name: "Suit Up!!",
    },
    {
      slug: "fnf",
      name: "Friends & Family",
    },
    {
      slug: "drool",
      name: "Droolworthy",
    },
    {
      slug: "boy",
      name: "Boy Next Door",
    },
  ],
  rohan: [
    {
      slug: "solos",
      name: "Solos",
    },
    {
      slug: "rockstar",
      name: "Rockstar",
    },
    {
      slug: "fitness",
      name: "Fitness",
    },
    {
      slug: "travel",
      name: "Travel",
    },
  ],
  sakshi: [
    {
      slug: "food",
      name: "Food Romance",
    },
    {
      slug: "fit",
      name: "Fit Selfies",
    },
    {
      slug: "slay",
      name: "Slaying Casually",
    },
    {
      slug: "travel",
      name: "Travel",
    },
  ],

  // Extras
  syscila: [
    {
      slug: "trvl",
      name: "Travel",
    },
    {
      slug: "asaiki",
      name: "Am Sexy And I Know It",
    },
    {
      slug: "imm",
      name: "I, Me & Myself",
    },
    {
      slug: "itc",
      name: "Oh! It's The Curls",
    },
  ],
  sifat: [
    {
      slug: "np",
      name: "Narcissim's Problem",
    },
    {
      slug: "hph",
      name: "Head Poser Heels",
    },
    {
      slug: "soml",
      name: "Shades of My Life",
    },
    {
      slug: "mf",
      name: "Modern Family",
    },
  ],
  shimona: [
    {
      slug: "flawless",
      name: "Drop-dead Flawless",
    },
    {
      slug: "store",
      name: "Achievements Store",
    },
    {
      slug: "face",
      name: "In Face You Didn't Know",
    },
    {
      slug: "dream",
      name: "A Midsummer White's Dream",
    },
  ],
  shivam: [
    {
      slug: "sns",
      name: "Smile & Shine",
    },
    {
      slug: "out",
      name: "Outing",
    },
    {
      slug: "ctl",
      name: "Cook The Looks",
    },
    {
      slug: "chill",
      name: "Chillin",
    },
  ],
  pratham: [
    {
      slug: "modl",
      name: "Modelling",
    },
    {
      slug: "fit",
      name: "Fitness & Lifestyle",
    },
    {
      slug: "travel",
      name: "Travel & Adventure",
    },
    {
      slug: "chill",
      name: "Chill Time",
    },
  ],
  manandeep: [
    {
      slug: "rip",
      name: "Ripped",
    },
    {
      slug: "glob",
      name: "Globetrotting",
    },
    {
      slug: "fb",
      name: "Football",
    },
    {
      slug: "fam",
      name: "Family",
    },
  ],
  faisal: [
    {
      slug: "wrk",
      name: "Work",
    },
    {
      slug: "wrkout",
      name: "Workout",
    },
    {
      slug: "selfies",
      name: "Selfies-Chill",
    },
    {
      slug: "me",
      name: "Being Me",
    },
  ],
  niharika: [
    {
      slug: "fnf",
      name: "Friends & Family",
    },
    {
      slug: "swty",
      name: "Sweety",
    },
    {
      slug: "sns",
      name: "Sugar & Spice",
    },
    {
      slug: "si",
      name: "Secret Ingredient",
    },
  ],
  zeenat: [
    {
      slug: "girl",
      name: "The Girl Next Door Diaries",
    },
    {
      slug: "light",
      name: "Let There Be Light",
    },
    {
      slug: "fit",
      name: "Fitness Is My Passion",
    },
    {
      slug: "cup",
      name: "My Hot Cup Of Tea",
    },
  ],
};

// Album Images

export interface AlbumImagePaths {
  [userSlug: string]: {
    [albumSlug: string]: string[];
  };
}

const albumImages: AlbumImagePaths = {};

const imageContext = require.context(
  "../../public/images/albums",
  true,
  /\.(png|PNG|jpe?g|JPE?G)$/
);

imageContext.keys().forEach((item) => {
  const imagePathParts = item.split("/");
  if (imagePathParts.length === 4) {
    const userSlug = imagePathParts[1];
    const albumSlug = imagePathParts[2];
    const imageFileName = imagePathParts[3];

    if (!(userSlug in albumImages)) {
      albumImages[userSlug] = {};
    }
    if (!(albumSlug in albumImages[userSlug])) {
      albumImages[userSlug][albumSlug] = [];
    }

    albumImages[userSlug][albumSlug].push(imageFileName);
  }
});

export { albumImages };
