import { SyntheticEvent, useState } from "react";

import { config } from "src/config";
import { setIsAdmin } from "src/store/users";
import { useAppDispatch } from "src/hooks/useAppDispatch";

import "./AdminLogin.scoped.scss";

export const AdminLogin = () => {
  const [password, setPassword] = useState("");

  const dispatch = useAppDispatch();

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (password === config.adminPassword) {
      dispatch(setIsAdmin(true));
    } else {
      alert("Invalid Password");
    }
  };

  return (
    <div className="mc-adminlog">
      <form
        className="mc-adminlog__container row m-3 g-3"
        onSubmit={handleSubmit}
      >
        {/* Title */}
        <div className="col-12">
          <h3 className="mc-adminlog__title">Hi Admin</h3>
          <h6 className="mc-adminlog__subtitle">
            Please enter your password...
          </h6>
        </div>

        {/* Password */}
        <div className="col-12">
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        {/* Buttons */}
        <div className="col-12">
          <button type="submit" className="btn btn-success me-2 w-100">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
