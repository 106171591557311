export const questions: Record<string, string> = {
  iAm: "I am...",
  iAmNot: "I am not...",
  imGoodAt: "I'm good at...",
  imBadAt: "I'm bad at...",
  imGreatAt: "I'm great at...",
  imPassionateAbout: "I'm passionate about...",
  turnedOnBy: "I'm turned on by...",
  turnedOffBy: "I'm turned off by...",
  cantLiveWithout: "I can't live without...",
  lieAbout: "If I had to lie about myself, I'd say…",
  warnAbout: "If I had to warn you about me, I'd say...",
  passFreeTime: "I pass my free time by...",
  arrestedFor: "If I ever get arrested, it'll be for...",
  sexiestPart: "My sexiest body part is...",
  worthHabit: "My worst habit is...",
  weakness: "My weakness is...",
  friendsWouldTell: "My friends would tell you...",
  exesWouldTell: "My exes would tell you…",
  sextingIs: "Sexting is…",
  todayMoodFor: "Today I'm in the mood for…",
};
