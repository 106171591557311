import { getHexCode, Theme } from "src/models/Theme";

import "./Question.scoped.scss";

interface PropTypes {
  className?: string;
  question: string;
  answer: string;
  theme: Theme;
}

export const Question = (props: PropTypes) => {
  const { className, question, answer, theme } = props;

  return (
    <div className={`mc-question ${className ? className : ""}`}>
      {/* Question */}
      <div className="mc-question__text">{question}</div>

      {/* Answer */}
      <div
        className="mc-question__answer"
        style={{ backgroundColor: getHexCode(theme) }}
      >
        {answer}
      </div>
    </div>
  );
};
