import { configureStore } from "@reduxjs/toolkit";

import { config } from "src/config";
import { usersReducer } from "./users";
import { alertsReducer } from "./alerts";
import { settingsReducer } from "./settings";
import { messagesReducer } from "./messages";
import { chatRequestsReducer } from "./chat-requests";
import { videoRequestReducer } from "./video-requests";

export const store = configureStore({
  reducer: {
    users: usersReducer,
    settings: settingsReducer,
    messages: messagesReducer,
    alerts: alertsReducer,
    chatRequests: chatRequestsReducer,
    videoRequests: videoRequestReducer,
  },
  devTools: config.env !== "production",
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
