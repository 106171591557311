import { useMemo, useRef } from "react";

import { Page } from "src/models/Page";
import { User } from "src/models/User";
import { ChatRequest } from "src/models/ChatRequest";
import { VideoRequest } from "src/models/VideoRequest";
import { getConnectionsDoodleImage, getGradientImage } from "src/models/Theme";

import { Connection } from "./Connection";
import { HiddenNavigation } from "../Common/HiddenNavigation";

import { ref, set } from "firebase/database";
import { firebaseDB } from "src/utils/firebase";

import { store } from "src/store";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { setCurrentPage, setUserToShow } from "src/store/users";

import "./Connections.scoped.scss";
import "./Connections.scss";

export const Connections = () => {
  const dispatch = useAppDispatch();
  const isLoading = useRef(false);

  // Users

  const userList = useAppSelector(
    (state) => state.users.list as Record<string, User>
  );
  const authUser = useAppSelector((state) => state.users.authUser as User);
  const authUserSlug = useAppSelector(
    (state) => state.users.authUserSlug as string
  );

  // Connections

  const myConnections = useMemo(() => {
    const connections = authUser.connections ? authUser.connections : {};
    return Object.keys(connections).filter(
      (connectionSlug) => connections[connectionSlug] === true
    );
  }, [authUser.connections]);

  const getConnectionSize = useMemo(() => {
    if (myConnections.length <= 2) {
      return "xl";
    } else if (myConnections.length <= 4) {
      return "lg";
    } else if (myConnections.length <= 6) {
      return "md";
    } else {
      return "sm";
    }
  }, [myConnections]);

  // Chat System

  const chatRequestSystemEnabled = useAppSelector(
    (state) => state.settings.value.chatRequestSystem
  );

  // Button Clicks

  const handleBackNavigation = () => {
    dispatch(setCurrentPage(Page.Profile));
  };

  const handleVideoCallClick = (toSlug: string) => {
    if (!isLoading.current) {
      isLoading.current = true;
      const timestamp = new Date().getTime();
      const request: VideoRequest = {
        from: authUserSlug,
        to: toSlug,
        status: "P",
        timestamp: timestamp,
      };
      set(ref(firebaseDB, `videoRequests/${timestamp}`), request)
        .catch((error) => {
          alert(JSON.stringify(error));
        })
        .then(() => {
          isLoading.current = false;
        });
    }
  };

  const handleProfileClick = (slug: string) => {
    dispatch(setUserToShow(slug));
    dispatch(setCurrentPage(Page.Profile));
  };

  const isChattingWithThisConnection = (connectionSlug: string): boolean => {
    const allChatRequests = store.getState().chatRequests.list;
    const allChatRequestIds = Object.keys(allChatRequests);
    for (let i = 0; i < allChatRequestIds.length; i++) {
      const currentChatRequestId = allChatRequestIds[i];
      const currentChatRequest = allChatRequests[currentChatRequestId];
      if (
        ["A", "AS"].includes(currentChatRequest.status) &&
        ((currentChatRequest.from === connectionSlug &&
          currentChatRequest.to === authUserSlug) ||
          (currentChatRequest.from === authUserSlug &&
            currentChatRequest.to === connectionSlug))
      ) {
        return true;
      }
    }
    return false;
  };

  const handleChatClick = (connectionSlug: string) => {
    if (chatRequestSystemEnabled === "F") {
      dispatch(setUserToShow(connectionSlug));
      dispatch(setCurrentPage(Page.Chat));
    } else {
      if (isChattingWithThisConnection(connectionSlug)) {
        dispatch(setUserToShow(connectionSlug));
        dispatch(setCurrentPage(Page.Chat));
      } else {
        // Send chat request
        if (!isLoading.current) {
          isLoading.current = true;
          const timestamp = new Date().getTime();
          const chatRequestData: ChatRequest = {
            from: authUserSlug,
            to: connectionSlug,
            status: "P",
            timestamp: timestamp,
          };
          set(ref(firebaseDB, `chatRequests/${timestamp}`), chatRequestData)
            .catch((error) => {
              alert(JSON.stringify(error));
            })
            .then(() => {
              isLoading.current = false;
            });
        }
      }
    }
  };

  // JSX
  return (
    <div
      className="mc-connections"
      style={{
        background: `url("${getGradientImage(
          authUser.theme
        )}") no-repeat center center`,
      }}
    >
      {/* Pattern */}
      <div
        className="mc-connections__pattern"
        style={{
          background: `url("${getConnectionsDoodleImage(
            authUser.theme
          )}") no-repeat center center`,
        }}
      />

      {/* Connections Grid */}
      <div
        className={`mc-connections__list mc-connections__list--${getConnectionSize}`}
      >
        {myConnections.map((connectionSlug) => {
          return (
            <Connection
              key={connectionSlug}
              className="mc-connections__item"
              size={getConnectionSize}
              user={userList[connectionSlug]}
              userSlug={connectionSlug}
              onVideoCallClick={() => handleVideoCallClick(connectionSlug)}
              onProfileClick={() => handleProfileClick(connectionSlug)}
              onChatClick={() => handleChatClick(connectionSlug)}
            />
          );
        })}
      </div>

      {/* Buttons */}
      <HiddenNavigation onLeftClick={handleBackNavigation} />
    </div>
  );
};
