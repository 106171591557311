export const config = {
  env: process.env.NODE_ENV,
  adminPassword: "aphrodite",
  firebase: {
    apiKey: "AIzaSyAtJZsNDls24bDwBGdhA-NmFAzWWRy3-lQ",
    authDomain: "meetcute-1110a.firebaseapp.com",
    databaseURL:
      "https://meetcute-1110a-default-rtdb.asia-southeast1.firebasedatabase.app/",
    projectId: "meetcute-1110a",
    storageBucket: "meetcute-1110a.appspot.com",
    messagingSenderId: "575710136191",
    appId: "1:575710136191:web:09f2bf02d106a9531e7c4a",
  },
};
