import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ChatRequest } from "src/models/ChatRequest";
import { RootState } from ".";

export interface ChatRequestsState {
  list: Record<string, ChatRequest>;
}

const initialState: ChatRequestsState = {
  list: {},
};

export const chatRequestsSlice = createSlice({
  name: "chat-requests",
  initialState,
  reducers: {
    setChatRequests: (
      state,
      action: PayloadAction<Record<string, ChatRequest>>
    ) => {
      state.list = action.payload;
    },
  },
});

const selectAuthUserSlug = (state: RootState) => state.users.authUserSlug;
const selectAllChatRequests = (state: RootState) => state.chatRequests.list;

export const selectMyChatRequests = createSelector(
  selectAuthUserSlug,
  selectAllChatRequests,
  (authUserSlug, allChatRequests) => {
    const myRequests: ChatRequest[] = [];
    Object.keys(allChatRequests).forEach((chatRequestID) => {
      const currentChatRequest = allChatRequests[chatRequestID];
      if (
        currentChatRequest.from === authUserSlug ||
        currentChatRequest.to === authUserSlug
      ) {
        myRequests.push(currentChatRequest);
      }
    });
    return myRequests;
  }
);

export const selectMyOpenChatRequests = createSelector(
  selectAuthUserSlug,
  selectMyChatRequests,
  (authUserSlug, myRequests) => {
    const list: ChatRequest[] = [];
    myRequests.forEach((myRequest) => {
      if (myRequest.status === "P") {
        list.push(myRequest);
      } else if (myRequest.status === "A" && myRequest.from === authUserSlug) {
        list.push(myRequest);
      } else if (myRequest.status === "R" && myRequest.from === authUserSlug) {
        list.push(myRequest);
      }
    });
    return list;
  }
);

export const selectChattingWith = createSelector(
  selectAuthUserSlug,
  selectMyChatRequests,
  (authUserSlug, myRequests) => {
    for (let i = 0; i < myRequests.length; i++) {
      const myRequest = myRequests[i];
      if (["A", "AS"].includes(myRequest.status)) {
        if (myRequest.from === authUserSlug) {
          return myRequest.to;
        } else {
          return myRequest.from;
        }
      }
    }
  }
);

export const selectIsChattingWithSomeone = createSelector(
  selectChattingWith,
  (chattingWith) => {
    return !!chattingWith;
  }
);

export const { setChatRequests } = chatRequestsSlice.actions;

export const chatRequestsReducer = chatRequestsSlice.reducer;
