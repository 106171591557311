import { SyntheticEvent, useState } from "react";
import {
  getConnectionsDoodleImage,
  getGradientImage,
  getHexCode,
} from "src/models/Theme";
import { User } from "src/models/User";

import "./Login.scoped.scss";

interface PropTypes {
  user: User;
  userSlug: string;
  onVerified: () => void;
}

export const Login = ({ user, userSlug, onVerified }: PropTypes) => {
  const [password, setPassword] = useState("");

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (user.password && user.password === password) {
      onVerified();
    } else {
      alert("Invalid Password");
    }
  };

  return (
    <div
      className="mc-login"
      style={{
        background: `url("${getGradientImage(
          user.theme
        )}") no-repeat center center`,
      }}
    >
      {/* Doodles */}
      <div
        className="mc-login__doodles"
        style={{
          background: `url("${getConnectionsDoodleImage(
            user.theme
          )}") no-repeat center center`,
        }}
      />

      {/* Form */}
      <form className="mc-login__container row m-3 g-3" onSubmit={handleSubmit}>
        {/* Title */}
        <div className="col-12">
          <h3 className="mc-login__title">
            Hi{" "}
            {userSlug.charAt(0).toUpperCase() + userSlug.slice(1).toLowerCase()}
          </h3>
          <h6 className="mc-login__subtitle">Please enter your password...</h6>
        </div>

        {/* Password */}
        <div className="col-12">
          <input
            type="password"
            className="form-control"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        {/* Buttons */}
        <div className="col-12">
          <button
            type="submit"
            className="btn btn-success me-2 w-100"
            style={{
              backgroundColor: getHexCode(user.theme),
              borderColor: getHexCode(user.theme),
            }}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
