import { useState, useMemo } from "react";

import { Question } from "./Question";
import { Frame } from "../Common/Frame";
import { HiddenNavigation } from "../Common/HiddenNavigation";

import { Page } from "src/models/Page";
import { questions } from "src/data/questions";
import { getQuestionsPhotoURL, User } from "src/models/User";
import { getDoodleImage, getGradientImage } from "src/models/Theme";

import { useAppDispatch } from "src/hooks/useAppDispatch";
import { useAppSelector } from "src/hooks/useAppSelector";
import { setCurrentPage, setUserToShow } from "src/store/users";

import "./Questions.scoped.scss";
import "./Questions.scss";

const PAGE_SIZE = 4;

export const Questions = () => {
  // Redux
  const dispatch = useAppDispatch();
  const userToShow = useAppSelector((state) => state.users.userToShow as User);
  const authUserSlug = useAppSelector(
    (state) => state.users.authUserSlug as string
  );
  const userToShowSlug = useAppSelector(
    (state) => state.users.userToShowSlug as string
  );
  const [questionPageNum, setQuestionPageNum] = useState(1);

  // Memos

  const totalQuestionPages = useMemo(() => {
    return Math.ceil(Object.keys(questions).length / PAGE_SIZE);
  }, []);

  const isFirstQuestionPage = useMemo(() => {
    return questionPageNum === 1;
  }, [questionPageNum]);

  const isLastQuestionPage = useMemo(() => {
    return questionPageNum === totalQuestionPages;
  }, [questionPageNum, totalQuestionPages]);

  const isMyQuestions = useMemo(() => {
    return authUserSlug === userToShowSlug;
  }, [authUserSlug, userToShowSlug]);

  // Functions

  const handlePreviousQuestionsClick = () => {
    if (questionPageNum > 1) {
      setQuestionPageNum(questionPageNum - 1);
    }
  };

  const handleNextQuestionsClick = () => {
    if (questionPageNum < totalQuestionPages) {
      setQuestionPageNum(questionPageNum + 1);
    }
  };

  const handleBackNavigation = () => {
    if (isMyQuestions) {
      dispatch(setCurrentPage(Page.Profile));
    } else {
      dispatch(setUserToShow(userToShowSlug));
      dispatch(setCurrentPage(Page.Profile));
    }
  };

  // JSX
  return (
    <div
      className="mc-questions"
      style={{
        background: `url("${getGradientImage(
          userToShow.theme
        )}") no-repeat center center`,
      }}
    >
      {/* Left Side */}
      <div className="mc-questions__left">
        {/* Doodles */}
        <div
          className="mc-questions__doodles"
          style={{
            background: `url("${getDoodleImage(
              userToShow.theme
            )}") no-repeat center right`,
          }}
        />

        {/* Photo */}
        <Frame
          className="mc-questions__photo"
          src={getQuestionsPhotoURL(userToShow)}
        />
      </div>

      {/* Right Side */}
      <div className="mc-questions__right">
        <div className="mc-questions__main">
          {/* Questions */}
          {Object.keys(questions)
            .slice(
              (questionPageNum - 1) * PAGE_SIZE,
              questionPageNum * PAGE_SIZE
            )
            .map((questionSlug) => {
              return (
                <Question
                  key={questionSlug}
                  className="mb-3"
                  question={questions[questionSlug]}
                  answer={
                    userToShow.answers && userToShow.answers[questionSlug]
                      ? userToShow.answers[questionSlug]
                      : "-"
                  }
                  theme={userToShow.theme}
                />
              );
            })}

          {/* Nav Buttons */}
          <div className="mc-questions__nav">
            {!isFirstQuestionPage && (
              <img
                className="mc-questions__nav-btn"
                src="/images/buttons/arrow_left.png"
                onClick={handlePreviousQuestionsClick}
              />
            )}
            {!isLastQuestionPage && (
              <img
                className="mc-questions__nav-btn"
                src="/images/buttons/arrow_right.png"
                onClick={handleNextQuestionsClick}
              />
            )}
          </div>
        </div>
      </div>

      {/* Back Button */}
      <HiddenNavigation onLeftClick={handleBackNavigation} />
    </div>
  );
};
