import { RouteObject } from "react-router-dom";

import { Home } from "src/components/Home";
import { UserContainer } from "./components/User/Container";

import { Chat } from "./components/Admin/Chat";
import { AdminHome } from "./components/Admin/Home";
import { Settings } from "./components/Admin/Settings";
import { UserList } from "./components/Admin/Users/UserList";
import { UserEdit } from "./components/Admin/Users/UserEdit";
import { SendAlert } from "./components/Admin/Alerts/SendAlert";

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/user/:slug",
    element: <UserContainer />,
  },
  {
    path: "/admin",
    children: [
      {
        path: "",
        element: <AdminHome />,
      },
      {
        path: "settings",
        element: <Settings />,
      },
      {
        path: "users",
        element: <UserList />,
      },
      {
        path: "users/:slug",
        element: <UserEdit />,
      },
      {
        path: "chats",
        element: <Chat />,
      },
      {
        path: "send-notification",
        element: <SendAlert />,
      },
    ],
  },
];
